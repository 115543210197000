import { apiSlice } from "./apiSlice";
const USERS_URL = '/api/Authentication';
 const getAccessTokenFromLocalStorage = () => {
  const userDataString = localStorage.getItem("userData");
   const userData = userDataString ? JSON.parse(userDataString) : null;
   const token =  userData?.token ;
  return token;
};

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
        query: (data) => ({
          url:  `${USERS_URL}/login`,
          method: 'POST',
          body: data,
        }),
      }),
      register: builder.mutation({
        query: (data) => ({
          url:`${USERS_URL}/PatientRegistration` ,
          method: 'POST',
          body: data,
        }),
       }),
     Hospitalregister: builder.mutation({
      query: (data) => ({
        url:`${USERS_URL}/HospitalRegistration` ,
        method: 'POST',
        body: data,
      }),
     }),


     Doctorregister: builder.mutation({
      query: (data) => ({
        url:`${USERS_URL}/DoctorRegistration` ,
        method: 'POST',
        body: data,
        
      }),
     }),

     
     

    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: 'POST',
      }),
    }),
    


      

  
       

  }),
});

export const { useLoginMutation, useLogoutMutation,useRegisterMutation,useHospitalregisterMutation,useDoctorregisterMutation} = usersApiSlice;