import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Loader from '../components/Loader';
import '../styles/OTPverificationStyles.css';
import FormContainer from '../components/FormContainer';
import apiInstance from './AxiosConfig';
import { toast } from 'react-toastify';

export default function OtpVerification() {
    const [OTPnumber, setOTPnumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state.username;

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await apiInstance.post('/api/Authentication/OtpVerification', { OTPnumber, username });
            if (response.data === "OTP number verified") {
                window.location.replace('/');
            } else {
                toast.error('OTP verification failed');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendOTP = async () => {
        try {
            setIsLoading(true);
            const response = await apiInstance.post('/api/Authentication/OtpResend', { username });
            
            if (response && response.status === 200) {
            if (response.data === "success") {
                toast.success('OTP has been resent successfully');
            } else {
                toast.error('Failed to resend OTP');
            }}
        } catch (error) {
            console.error('Error resending OTP:', error);
            toast.error('Failed to resend OTP');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bckground-image">
            <FormContainer>
                <h1>Check your email</h1>
                <Form onSubmit={submitHandler}>
                    <Form.Group className='my-2' controlId='otpNumber'>
                        <Form.Label>OTP number</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter OTP number'
                            value={OTPnumber}
                            onChange={(e) => setOTPnumber(e.target.value)}
                        />
                    </Form.Group>
                    {isLoading && <Loader />}
                    <Button type='submit' variant='primary' className='mt-3 submit-button'>
                        Submit
                    </Button>
                </Form>
                <div className="resend-button-container">
                    <Button onClick={handleResendOTP} disabled={isLoading} className='resend-button'>
                        Resend OTP
                    </Button>
                </div>
            </FormContainer>
        </div>
    );
}
