import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiInstance from "./AxiosConfig";

const ProductDisplay = ({ paymentAmount, handleCheckout }) => (
  <section>
    <div className="product">
      <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="Payment amount"
      />
      <div className="description">
        <h3>Payment Amount</h3>
        <h5>${paymentAmount}</h5>
      </div>
    </div>
    <button onClick={handleCheckout}>
      Checkout
    </button>
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const StripeTest = () => {
  const [message, setMessage] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchPaymentAmount = async () => {
      try {
        const consultationType = location.state?.consultationType;
        if (consultationType) {
          const response = await apiInstance.get(`/api/Patient/GetConsultationAmount?ConsultationType=${consultationType}`);
          if (response.data.length > 0) {
            setPaymentAmount(response.data[0].amount);
          }
        }
      } catch (error) {
        console.error('Error fetching payment amount:', error.message);
      }
    };

    fetchPaymentAmount();
  }, [location]);

  const handleCheckout = async () => {
    try {
      
      const response = await apiInstance.post('https://usermanagementapi20240819201611.azurewebsites.net/create-checkout-session', {
       
      });

      
    } catch (error) {
      console.error('Error during checkout:', error.message);
    }
  };

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay paymentAmount={paymentAmount} handleCheckout={handleCheckout} />
  );
};

export default StripeTest;
