import { Route, Routes } from "react-router-dom";
import RegisterScreen from "../pages/PatientRegistration";
import HospitalRegisterScreen from "../pages/HospitalRegistration";
import LoginScreen from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";

import OtpVerification from "../pages/OtpVerification";
const AuthRoute= ()=>{
    return (
      <Routes>
        <Route path="/Patientregister" element={<RegisterScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/hospitalregister" element={<HospitalRegisterScreen/>} />
        <Route path="/forgotpassword" element={<ForgotPassword/>} />
        <Route path="/otpdetails" element={<OtpVerification/>} />


        
      </Routes>
    );
    }
    export default AuthRoute;