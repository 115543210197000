import React, { useState, useEffect } from "react";
import { Table, Button,message } from "antd";
import Layout from './../components/Layout'; 
import apiInstance from './AxiosConfig';
import { useLocation } from "react-router-dom";


const DoctorNotificationPage = () => {
  const location = useLocation();
  const [notifications, setNotifications] = useState(location.state?.notificationDetails || []);
  const [roleName, setRoleName] = useState(location.state?.roleName || "");
  const userDataString = localStorage.getItem('userData');
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const token = userData?.token;
  
    useEffect(() => {
      console.log("Notifications data received:", notifications);
      console.log("RoleName:",roleName);
      
    }, [notifications]);
    const renderPatientContent = () => {
      console.log("Rendering patient content...");
      const messageText = notifications[0]?.message;
      
     
      if (messageText === "accept") {
        updateNotificationStatus(notifications);
        return <p>Your doctor request has been approved.</p>;
      } else if (messageText === "reject") {
        updateNotificationStatus(notifications);
        return <p>Your doctor assign request has been rejected.</p>;
      } else {
        return <p>{messageText}</p>;
      }
    };
    const updateNotificationStatus = async (notifications) => {
    try{
      if(notifications.length>0){
       
        console.log("notification",notifications[0]);
        console.log("doctorId",notifications[0].doctorId);
        console.log("PatientIdId",notifications[0].patientId);
          // Make a request to your API endpoint to update notification status
          const doctorId=notifications[0].doctorId;
          const patientId=notifications[0].patientId;
          const requestConfig = { doctorId, patientId };
          console.log("Axios request config:", requestConfig);
          
          await apiInstance.post('/api/Notification/UpdatePatientNotificationStatus', requestConfig);
      }
      else {
        console.warn("No notifications available");
      }
      }
      catch (error) {
        // Handle errors, show error message
        console.error("Error updating notification status:", error);
      }
  
    };
  const columns = [
    {
      title: "Type of Request",
      dataIndex: "notificationType",
      key: "notificationType",
    },
    {
      title: "Name of Patient",
      dataIndex: "userName", 
      key: "userName",
    },
    {
      title: "DateTime",
      dataIndex: "dateTime",
      key: "dateTime",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={() => handleAccept(record)}>
            Accept
          </Button>{" "}
          <Button type="danger" onClick={() => handleReject(record)}>
            Reject
          </Button>
        </span>
      ),
    },
  ];

  const handleAccept = (record) => {
    sendApiRequest(record.doctorId,record.patientId, "accept",record);
  };

  const handleReject = (record) => {
    sendApiRequest(record.doctorId,record.patientId, "reject",record);
  };

  const sendApiRequest = async (doctorId,patientId, action,record) => {
    const endpoint = `/api/Notification/DoctorResponseToTheNotification?doctorId=${doctorId}&patientId=${patientId}&action=${action}`;
  
    try {
      // Send API request without waiting for the response
      apiInstance.post(endpoint);
  
      // Update the state to remove the processed notification
      setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification !== record));
      
      // Show success message
      message.success(`${action} request sent.`);
    } catch (error) {
      // Handle errors, show error message
      console.error("Error sending API request:", error);
      message.error(`${action} request failed.`);
    }
  };
  
  return (
    <Layout>
      <div>
        <h1>Notification Page</h1>
        {roleName === "Patient" ? (
          
          renderPatientContent()
        ) : (
          // Render content for Doctor
          <Table dataSource={notifications} columns={columns} />
        )}
      </div>
    </Layout>
  );
};


export default DoctorNotificationPage;
