import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

const getAccessTokenFromLocalStorage = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const token = userData?.token;
  return token;
};

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://usermanagementapi20240819201611.azurewebsites.net/',
 //baseUrl:'https://localhost:5001/',
  headers: {
    'Content-Type': 'application/json',
    // Add Authorization header using the token from localStorage
    Authorization: `Bearer ${getAccessTokenFromLocalStorage()}`,
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ['User', 'DoctorList'],
  endpoints: (builder) => ({}),
});
