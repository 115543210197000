import { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHospitalregisterMutation } from '../redux/features/usersApiSlice';
import { setCredentials } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import '../styles/RegisterStyles.css';

const HospitalRegisterScreen = () => {
  const [username, setUsername] = useState('');
 
  const [firstname, setFirstname] = useState('');
  
  const [lastname, setLastname] = useState('');
  
  const [email, setEmail] = useState('');
  
  const [password, setPassword] = useState('');
  
  const [confirmpassword, setConfirmpassword] = useState('');

  const[phonenumber,setPhonenumber]=useState('');
  
  const[streetaddress,setStreetaddress]=useState('');
  

  const[city,setCity]=useState('');
  

  const[country,setCountry]=useState('');
 

  const[postalcode,setPostalcode]=useState('');
  
  const[dateofbirth,setDateofbirth]=useState('');
  

  const[hospitalname,setHospitalname]=useState('');
  
  

  const[personalnumber,setPersonalnumber]=useState('');
  
  

  const dispatch = useDispatch();
const navigate = useNavigate();

const [ Hospitalregister, { isLoading }] = useHospitalregisterMutation();

const { userData } = useSelector((state) => state.auth);



  const submitHandler = async (e) => {
  e.preventDefault();
  


  if (password != confirmpassword) {
    toast.error('Passwords do not match');
  }
  if(!username || !firstname || !lastname || !email || !password || !confirmpassword || !phonenumber || !streetaddress || !city || !country || !postalcode || !dateofbirth || !hospitalname ||  !personalnumber){
    toast.error('All fields are required');
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    toast.error('Invalid email format');
  }
  
  
    try {
      const res = await  Hospitalregister({ phonenumber,username, firstname,lastname,email, password,confirmpassword,streetaddress,city,country,postalcode,dateofbirth,hospitalname,personalnumber }).unwrap();
      console.log("res",res);
      dispatch(setCredentials({ ...res }));
      toast.success('Registration successful!');
      console.log("registatin");

      navigate('/login');
      
    } catch (err) {
      toast.error(err?.data?.message || err.error);
      console.error("regerror",err);
    }
  
};

  return (
    <div className="imageholder">
    {/* Background image */}
    <div className="background-image"></div>
    <FormContainer>
      <h1>Hospital Registeration</h1>
      <Form onSubmit={submitHandler}>



      <Form.Group className='my-2' controlId='hospitalname'>
          <Form.Label>HospitalName</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Hospital name'
            value={hospitalname}
            onChange={(e) => setHospitalname(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>

       
      <Form.Group className='my-2' controlId='username'>
          <Form.Label>UserName</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>


        
        <Form.Group className='my-2' controlId='firstname'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Firstname'
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>


        <Form.Group className='my-2' controlId='lastname'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Lastname'
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='phonenumber'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='tel'  pattern="[0-9]{10}"
            
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          ></Form.Control>
        </Form.Group>
         

        <Form.Group className='my-2' controlId='streetaddress'>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Street Address'
            value={streetaddress}
            onChange={(e) => setStreetaddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>

        </Form.Group>

        <Form.Group className='my-2' controlId='postalcode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Postal Code'
            value={postalcode}
            onChange={(e) => setPostalcode(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='dateofbirth'>
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter Date Of Birth'
            value={dateofbirth}
            onChange={(e) => setDateofbirth(e.target.value)}
          ></Form.Control>
        </Form.Group>
        

        

        <Form.Group className='my-2' controlId='personalnumber'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Personal number'
            value={personalnumber}
            onChange={(e) => setPersonalnumber(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='confirmpassword'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Confirm Password'
            value={confirmpassword}
            onChange={(e) => setConfirmpassword(e.target.value)}
          ></Form.Control>
        </Form.Group>


        
        
        {
     isLoading && <Loader />
        }
        <Button type='submit' variant='primary' className='mt-3'  >
          Register
        </Button>
      </Form>

      <Row className='py-3'>
        <Col>
          Already have an account? <Link to={`/login`}>Login</Link>
        </Col>
      </Row>
    </FormContainer>
    </div>
  );
};

export default HospitalRegisterScreen;