import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import '../styles/Doctoravailabilityinweeks.css';
import Layout from '../components/Layout';
import apiInstance from './AxiosConfig';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom';

const DoctorAvailabilityinWeek = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [availabilitySlots, setAvailabilitySlots] = useState([]);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const { userData } = useSelector((state) => state.auth);
  const UserId = userData?.user?.id;
  const naviagte=useNavigate();

  useEffect(() => {
    const fetchSelectedDates = async () => {
      try {
        const apiUrl = `/api/Doctor/GetAvailableDates?UserId=${UserId}`;
        const response = await apiInstance.get(apiUrl);

        if (response.data && response.data.length > 0) {
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          // Filter dates greater than or equal to the current date
          const filteredDates = response.data
            .map((date) => new Date(date.workingDate))
            .filter((date) => date >= currentDate);

          setHighlightedDates(filteredDates);
        }
      } catch (error) {
        console.error('Error fetching selected dates:', error.message);
      }
    };

    fetchSelectedDates();
  }, [UserId]);

  const handleDateChange = (dates) => {
    // Generate all dates within the selected range
    const generatedDates = [];
    for (let date = new Date(dates[0]); date <= dates[1]; date.setDate(date.getDate() + 1)) {
      generatedDates.push(new Date(date));
    }
    setSelectedDates(generatedDates);
  };
  
  const addAvailabilitySlot = () => {
    if (fromTime && toTime && new Date(`2000-01-01 ${toTime}`) > new Date(`2000-01-01 ${fromTime}`)) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
  
      const newSlot = { from: fromTime, to: toTime };
  
      // Check if the slot already exists
      const slotExists = availabilitySlots.some(
        (slot) => slot.from === newSlot.from && slot.to === newSlot.to
      );
  
      if (!slotExists) {
        // Add the slot only once
        setAvailabilitySlots([...availabilitySlots, newSlot]);
        setFromTime('');
        setToTime('');
      } else {
        toast.error('This time slot already exists. Please enter a different slot.');
      }
    } else {
      toast.error('Invalid time range. "To" time must be greater than "From" time.');
    }
  };
  
  const removeAvailabilitySlot = (indexToRemove) => {
    const updatedSlots = [...availabilitySlots];
    updatedSlots.splice(indexToRemove, 1);
    setAvailabilitySlots(updatedSlots);
  };
  
  const saveAvailability = async () => {
    if (availabilitySlots.length === 0) {
      toast.error('Please add at least one availability slot.');
      return;
    }
  
    try {
      const apiUrl = '/api/Doctor/SaveDoctorAvailability';
      const requestData = {
        userId: UserId,
        WorkingDates: selectedDates,
        availabilitySlots,
      };
  
      const response = await apiInstance.post(apiUrl, requestData);
  
      if (response && response.status === 200 && response.data.message === 'Success') {
        setHighlightedDates([...highlightedDates, ...selectedDates]);
        setAvailabilitySlots([]);
        toast.success('Availability slots saved successfully');

        naviagte('/doctorAvailability');
      }
       else {
        toast.error('Failed to save availability.');
      }
    } catch (error) {
      console.error('Error saving availability:', error.message);
      toast.error('Failed to save availability.');
    }
  };
  

  return (
    <Layout>
      <div className="doctor-availability-container">
        <div className="calendar-container">
          {/* Calendar for selecting multiple dates */}
          <Calendar
            onChange={handleDateChange}
            value={selectedDates}
            selectRange={true} // Enable range selection
            tileClassName={({ date }) =>
              highlightedDates.some(
                (highlightedDate) => highlightedDate.toDateString() === date.toDateString()
              )
                ? 'highlighted-date'
                : ''
            }
          />
        </div>
        <div className="availability-details-container">
          {/* Display selected dates */}
  <div className="selected-dates">
    <h3>Selected Dates:</h3>
    {selectedDates.map((date, index) => (
      <div key={index}>{date.toDateString()}</div>
    ))}
    {selectedDates.length > 0 && (
    <button onClick={() => setSelectedDates([])}>Clear</button>
  )}
  </div>
          {/* Inputs for time range */}
          <div>
            <label>From:</label>
            <input type="time" value={fromTime} onChange={(e) => setFromTime(e.target.value)} />
          </div>
          <div>
            <label>To:</label>
            <input type="time" value={toTime} onChange={(e) => setToTime(e.target.value)} />
          </div>
          <button onClick={addAvailabilitySlot}>Add Slot</button>
          
          
          {/* Display selected availability slots */}
         
          <div className="availability-slots">
            {availabilitySlots.map((slot, index) => (
              <div key={index} className="selected">
                {slot.from} - {slot.to}
                <button onClick={() => removeAvailabilitySlot(index)}>Remove</button>
              </div>
            ))}
          </div>
          {/* Button to save availability */}
          <button onClick={saveAvailability}>Save Availability</button>
        </div>
      </div>
    </Layout>
  );
};

export default DoctorAvailabilityinWeek;
