// EditDoctorScreen.js

import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDoctorByIdQuery, useUpdateDoctorMutation } from '../redux/features/doctorListApiSlice';
import Loader from '../components/Loader';
import Layout from './../components/Layout';
import { setCredentials } from '../redux/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const EditDoctorScreen = () => {
  const { doctorId } = useParams();
  
  const navigate = useNavigate();
  const { data: doctor, isLoading } = useGetDoctorByIdQuery(doctorId);
  const [updateDoctor, { isLoading: isUpdating }] = useUpdateDoctorMutation();
  const { userData } = useSelector((state) => state.auth);
 const adminId=userData?.user?.id;
 
   console.log("doctorvalues",doctor);
  // Individual state for each form field
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const[gender,setGender]=useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [experience, setExperience] = useState('');
  const [personalNumber, setPersonalNumber] = useState('');
  const [qualification, setQualification] = useState('');
  const [specialization, setSpecialization] = useState('');
   const[password,setPassword] =useState('');
  
  useEffect(() => {
    console.log("useEffect triggered", doctor);
    if (Array.isArray(doctor) && doctor.length > 0) {
      console.log("doctor values", doctor[0]);
      const firstDoctor = doctor[0];
      // Check if the properties exist before accessing them
      
      setUsername(firstDoctor?.username || '');
      setFirstName(firstDoctor?.firstName || '');
      setLastName(firstDoctor?.lastName || '');
      setGender(firstDoctor?.gender|| '');
      setEmail(firstDoctor?.email || '');
      setPhoneNumber(firstDoctor?.phoneNumber || '');
      setStreetAddress(firstDoctor?.streetAddress || '');
      setCity(firstDoctor?.city || '');
      setCountry(firstDoctor?.country || '');
      setPostalCode(firstDoctor?.postalCode || '');
      setDateOfBirth(firstDoctor?.dateOfBirth || '');
      setExperience(firstDoctor?.experience || '');
      setPersonalNumber(firstDoctor?.personalNumber || '');
      setQualification(firstDoctor?.qualification || '');
      setSpecialization(firstDoctor?.specialization || '');
      setPassword(firstDoctor?.password||'');
      
      console.log("dffdgffggf");
    }
  }, [doctor]);
  
console.log("doctoridvalue",doctorId);
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
        console.log('Submitting with doctorId:', doctorId);
      // Make an API call to update the doctor
      await updateDoctor( {
        
        data:{
          doctorId,
        username,
        firstName,
        lastName,
        gender,
        email,
        phoneNumber,
        streetAddress,
        city,
        country,
        postalCode,
        dateOfBirth,
        experience,
        personalNumber,
        qualification,
        specialization,
        password,
        adminId,
        
    },
       }
      ).unwrap();

      // Redirect to the doctor list page after a successful update
      toast.success('Profile updated successfully');
      
    } catch (error) {
      console.error('Error updating doctor:', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <FormContainer>
        <h1>Edit Doctor</h1>
        <Form onSubmit={submitHandler}>
         
          <Form.Group className='my-2' controlId='username'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='firstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Firstname'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='lastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Lastname'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='gender'>
          <Form.Label>Gender</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Gender'
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          ></Form.Control>
        </Form.Group>

          <Form.Group className='my-2' controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>


          <Form.Group className='my-2' controlId='phoneNumber'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='tel'  pattern="[0-9]{10}"
            
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>
         

        <Form.Group className='my-2' controlId='streetAddress'>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Street Address'
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>

        </Form.Group>

        <Form.Group className='my-2' controlId='postalCode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Postal Code'
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='dateOfBirth'>
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter Date Of Birth'
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          ></Form.Control>
        </Form.Group>
        

        

        <Form.Group className='my-2' controlId='personalNumber'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Personal number'
            value={personalNumber}
            onChange={(e) => setPersonalNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>


        

        <Form.Group className='my-2' controlId='qualification'>
          <Form.Label>Qualification</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter your qualification'
            value={qualification}
            onChange={(e) => setQualification(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>

        <Form.Group className='my-2' controlId='experience'>
          <Form.Label>Experience</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter number of experience'
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='specialization'>
          <Form.Label>Specialization</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter your specialization'
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter your password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

       
          <Button type='submit' variant='primary' className='mt-3' disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update'}
          </Button>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default EditDoctorScreen;
