import { apiSlice } from "./apiSlice";
const USERS_URL = '/api/PasswordReset';
 const getAccessTokenFromLocalStorage = () => {
  const userDataString = localStorage.getItem("userData");
   const userData = userDataString ? JSON.parse(userDataString) : null;
   const token =  userData?.token ;
  return token;
};

export const resetPasswordSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    
     


    
     
     resetPassword: builder.mutation({
      query: (data ) => ({
        url: `${USERS_URL}/PasswordResetRequest`,
        method: 'POST',
        body: data,
      }),
    }),

    

   
      

  
       

  }),
});

export const {useResetPasswordMutation} =resetPasswordSlice ;