

import React, { useState, useEffect } from 'react';
import { Form, Button,FormCheck } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPatientByIdQuery, useUpdatePatientMutation } from '../redux/features/profileSlice';
import Loader from '../components/Loader';
import Layout from './../components/Layout';
import { setCredentials } from '../redux/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PatientProfileScreen = () => {
  const { userData } = useSelector((state) => state.auth);
  const UserId=userData?.user?.id;
  
  const navigate = useNavigate();
  const { data: patient, isLoading } = useGetPatientByIdQuery(UserId);
  const [updatePatient, { isLoading: isUpdating }] = useUpdatePatientMutation();
 


   console.log("patientvalues",patient);
  // Individual state for each form field
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const[gender,setGender]=useState('');
  const[height,setHeight]=useState('');
  const[weight,setWeight]=useState('');
  const [personalNumber, setPersonalNumber] = useState('');
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
   
  useEffect(() => {
    console.log("useEffect triggered", patient);
    if (Array.isArray(patient) && patient.length > 0) {
      console.log("admin values", patient[0]);
      const firstpatient = patient[0];
      
      
      setUsername(firstpatient?.username || '');
      setFirstName(firstpatient?.firstName || '');
      setLastName(firstpatient?.lastName || '');
      setEmail(firstpatient?.email || '');
      setPhoneNumber(firstpatient?.phoneNumber || '');
      setStreetAddress(firstpatient?.streetAddress || '');
      setCity(firstpatient?.city || '');
      setCountry(firstpatient?.country || '');
      setPostalCode(firstpatient?.postalCode || '');
      setDateOfBirth(firstpatient?.dateOfBirth || '');
      setPersonalNumber(firstpatient?.personalNumber || '');
      
      setGender(firstpatient?.gender||'');
      setHeight(firstpatient?.height || '');
      setWeight(firstpatient?.weight || '');
      setTwoFactorAuth(firstpatient?.twofactorAuth || false);
      
    }
  }, [patient]);
  

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
        
      // Make an API call to update the doctor
      await updatePatient( {
        
        data:{
          UserId,
        username,
        firstName,
        lastName,
        email,
        phoneNumber,
        streetAddress,
        city,
        country,
        postalCode,
        dateOfBirth,
        gender,
        personalNumber,
       
        height,
        weight,
        twoFactorAuth,
       
        
    },
       }
      ).unwrap();

      // Redirect to the doctor list page after a successful update
      toast.success('Profile updated successfully');
      
    } catch (error) {
      console.error('Error updating doctor:', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <FormContainer>
        <h1>Your Profile</h1>
        <Form onSubmit={submitHandler}>
         
          <Form.Group className='my-2' controlId='username'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='firstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Firstname'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='lastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Lastname'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>


          <Form.Group className='my-2' controlId='phoneNumber'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='tel'  pattern="[0-9]{10}"
            
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>
         

        <Form.Group className='my-2' controlId='streetAddress'>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Street Address'
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>

        </Form.Group>

        <Form.Group className='my-2' controlId='postalCode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Postal Code'
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='dateOfBirth'>
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter Date Of Birth'
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          ></Form.Control>
        </Form.Group>
        

        

        <Form.Group className='my-2' controlId='personalNumber'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Personal number'
            value={personalNumber}
            onChange={(e) => setPersonalNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='gender'>
          <Form.Label>Gender</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Gender'
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='height'>
          <Form.Label>Height</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter Height'
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='weight'>
          <Form.Label>weight</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter Weight'
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2'>
            <FormCheck
              type='checkbox'
              id='twoFactorAuthCheckbox'
              label='Enable Two-Factor Authentication'
              checked={twoFactorAuth}
              onChange={(e) => setTwoFactorAuth(e.target.checked)}
            />
          </Form.Group>
        

          <Button type='submit' variant='primary' className='mt-3' disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update'}
          </Button>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default PatientProfileScreen;
