import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'; // Import moment.js for date formatting
import apiInstance from './AxiosConfig';
import '../styles/PatientPrescription.css'; // Import custom styles
import Layout from '../components/Layout';

const PatientPrescriptions = () => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [prescriptionsPerPage] = useState(2); // Adjust the number of prescriptions per page as needed
    const { userData } = useSelector((state) => state.auth);
    const userId = userData?.user?.id;
    
    useEffect(() => {
        const fetchPrescriptions = async () => {
            try {
                const response = await apiInstance.get(`/api/Prescription/GetPatientPrescriptionDetails?UserId=${userId}`);
                // Modify the date format here
                const modifiedPrescriptions = response.data.map(prescription => ({
                    ...prescription,
                    prescribedDate: moment(prescription.prescribedDate).format("YYYY-MM-DD"),
                    validUntil: moment(prescription.validUntil).format("YYYY-MM-DD")
                }));
                setPrescriptions(modifiedPrescriptions);
            } catch (error) {
                console.error('Error fetching prescriptions:', error);
            }
        };

        fetchPrescriptions();
    }, [userId]);

    // Get current prescriptions
    const indexOfLastPrescription = currentPage * prescriptionsPerPage;
    const indexOfFirstPrescription = indexOfLastPrescription - prescriptionsPerPage;
    const currentPrescriptions = prescriptions.slice(indexOfFirstPrescription, indexOfLastPrescription);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <Layout>
            <div className="prescription-list">
                <h2 className="page-title">Prescription List</h2>
                <ul className="prescription-items">
                    {currentPrescriptions.map((prescription, index) => (
                        <li key={index} className="prescription-item">
                            <div className="prescription-details">
                                <h3>{prescription.medicineName}</h3>
                                <p><strong>Dosage:</strong> {prescription.dossage}</p>
                                <p><strong>Duration:</strong> {prescription.duration}</p>
                                <p><strong>Area of Use:</strong> {prescription.areaOfUse}</p>
                                <p><strong>Prescribed Date:</strong> {prescription.prescribedDate}</p>
                                <p><strong>Valid Until:</strong> {prescription.validUntil}</p>
                                <p><strong>Notes:</strong> {prescription.notes}</p>
                                <p><strong>Repetition:</strong> {prescription.repetition}</p>
                                <p><strong>Time of the Day:</strong> {prescription.timeOfTheDay}</p>
                                <p><strong>To Be Taken:</strong> {prescription.toBeTaken}</p>
                                <p><strong>Prescribed By:</strong> {prescription.prescribedBy}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                {/* Pagination */}
                {prescriptions.length > prescriptionsPerPage && (
                    <div className="pagination">
                        <ul className="pagination-list">
                            {[...Array(Math.ceil(prescriptions.length / prescriptionsPerPage)).keys()].map(number => (
                                <li key={number}>
                                    <button onClick={() => paginate(number + 1)} className={currentPage === number + 1 ? 'active' : ''}>
                                        {number + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default PatientPrescriptions;
