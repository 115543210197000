import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Pagination } from 'react-bootstrap';
import Loader from '../components/Loader';
import Layout from '../components/Layout';

import { useGetDoctorsListQuery } from '../redux/features/doctorListApiSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiInstance from './AxiosConfig';

const DoctorListPage = () => {
  const { userData } = useSelector((state) => state.auth);
  const adminId = userData?.user?.id;
  const token = userData?.token;
  const { data: doctorsList, isLoading, refetch } = useGetDoctorsListQuery(adminId);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Set the number of items per page

  const filteredDoctors = doctorsList ? doctorsList.filter((doctor) =>
  doctor.firstName.toLowerCase().includes(searchQuery.toLowerCase())
) : [];

  const totalItems = filteredDoctors.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentDoctors = filteredDoctors.slice(startIndex, startIndex + itemsPerPage);

  const handleDeleteDoctor = async (doctorId) => {
    try {
      await apiInstance.delete(`/api/DoctorList?doctorId=${doctorId}`);
      refetch();
      toast.success('Doctor deleted successfully');
    } catch (error) {
      console.error('Error deleting doctor:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h1>Doctor List</h1>

      <Form className="mb-3">
        <Form.Group controlId="searchQuery">
          <Form.Label>Search by Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter doctor's name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" onClick={() => setCurrentPage(1)}>
          Search
        </Button>
      </Form>

      {currentDoctors.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Qualification</th>
                <th>Experience</th>
                <th>Specialization</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentDoctors.map((doctor) => (
                <tr key={doctor.doctorId}>
                  <td>{doctor.firstName}</td>
                  <td>{doctor.qualification}</td>
                  <td>{doctor.experience}</td>
                  <td>{doctor.specialization}</td>
                  <td>
                    <div className="d-flex">
                      <Link to={`/editDoctor/${doctor.doctorId}`}>
                        <Button variant="primary" size="sm" className="mr-2">
                          Edit
                        </Button>
                      </Link>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteDoctor(doctor.doctorId)}>
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination>
  <Pagination.Prev
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />
  {Array.from({ length: totalPages }, (_, index) => (
    <Pagination.Item
      key={index + 1}
      active={currentPage === index + 1}
      onClick={() => handlePageChange(index + 1)}
    >
      {index + 1}
    </Pagination.Item>
  ))}
  <Pagination.Next
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
</Pagination>
        </>
      ) : (
        <p>No doctors available.</p>
      )}
    </div>
  );
};

export default DoctorListPage;
