import { useSelector } from "react-redux";
import {Route,Routes}from "react-router-dom";
import ProfilePage from "../pages/Profile";

import DoctorRegisterScreen from "../pages/DoctorRegistration";

import HomePage from "../pages/HomePage";
import EditDoctorScreen from "../pages/EditDoctor";
import ProfileScreen from "../pages/Profile";
import ResetPassword from "../pages/ResetPassword";
const HospitalRoute=()=>{
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={< ProfileScreen/>} />
          
          <Route path="/addDoctor" element={<DoctorRegisterScreen/>}/>
          <Route path="/editDoctor/:doctorId" element={<EditDoctorScreen/>}/>
          <Route path="/ResetPassword" element={<ResetPassword/>} />
          
          
        </Routes>
      );
};
export default HospitalRoute;