import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import AuthRoute from "./routes/AuthRoute";
import HospitalRoute from "./routes/HospitalRoute";
import PatientRoute from "./routes/PatientRoute";
import { useEffect, useState } from "react";
import DoctorRoute from "./routes/DoctorRoute";



const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  console.log("userData",userData);
  console.log("usertoken",userData.token);
 
  // const location = useLocation();
  // const userDataString = localStorage.getItem("userData");
  // const userData = userDataString ? JSON.parse(userDataString) : null;
  // const token = userData ? userData.token : null;
  
  useEffect(() => {
  
    if (!userData?.token) {
      navigate("/login");
    }
  }, [userData?.token]);
  return <>{children}</>;
};

function App() {
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  
  console.log("role........" + userData?.user?.roleName);
  console.log(userData);
  const role = userData?.user?.roleName;
  console.log("role", role);
  return (
    <div>
      <Layout>
        {role === "Patient" && <PatientRoute />}
        {role === "Admin" && <HospitalRoute />}
        {role === "Doctor" && <DoctorRoute/>}
        
      </Layout>
      <AuthRoute />
    </div>
  );
}
export default App;
