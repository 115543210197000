import React, { useState, useEffect } from 'react';
import apiInstance from './AxiosConfig';
import Layout from '../components/Layout';
import '../styles/Consultations.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ConsultationsPage = () => {
  const [consultationStatus, setConsultationStatus] = useState('upcoming');
  const [consultationType, setConsultationType] = useState('');
  const [consultations, setConsultations] = useState([]);
  const { userData } = useSelector((state) => state.auth);
  const UserId = userData?.user?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [consultationsPerPage] = useState(5);

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        let url = '';
        if (consultationStatus === 'upcoming') {
          url = `/api/Patient/GetUpcomingBookingDetails?UserId=${UserId}`;
        } else if (consultationStatus === 'historic') {
          url = `/api/Patient/GetHistoricConsultationDetails?UserId=${UserId}`;
        } else if (consultationStatus === 'cancelled') {
          url = `/api/Patient/GetCancelledConsultationDetails?UserId=${UserId}`;
        }

        const response = await apiInstance.get(url);
        const consultationsData = response.data;
        setConsultations(consultationsData);
      } catch (error) {
        console.error('Error fetching consultations:', error.message);
      }
    };

    fetchConsultations();
  }, [UserId, consultationStatus]);

  const handleCancelConsultation = async (consultationId) => {
    try {
      await apiInstance.delete(`/api/Patient/CancelConsultation?ConsultationId=${consultationId}`);
      const updatedConsultations = consultations.filter((consultation) => consultation.consultationId !== consultationId);
      setConsultations(updatedConsultations);
      toast.success(`Consultation canceled successfully.`);
    } catch (error) {
      console.error('Error canceling consultation:', error.message);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  const totalPages = Math.ceil(consultations.length / consultationsPerPage);
  const startIndex = (currentPage - 1) * consultationsPerPage;
  const endIndex = Math.min(startIndex + consultationsPerPage, consultations.length);
  const currentConsultations = consultations.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div>
        <br />
        <h1>Consultations</h1>
        <br />
        <div>
          <label htmlFor="consultationStatus">Consultation Status:</label>
          <select id="consultationStatus" value={consultationStatus} onChange={(e) => setConsultationStatus(e.target.value)}>
            <option value="upcoming">Upcoming Consultations</option>
            <option value="historic">Historic Consultations</option>
            <option value="cancelled">Cancelled Consultations</option>
          </select>
        </div>
        <div>
          <label htmlFor="consultationType">Consultation Type:</label>
          <select id="consultationType" value={consultationType} onChange={(e) => setConsultationType(e.target.value)}>
            <option value="">All</option>
            <option value="Physical">Physical Consultation</option>
            <option value="Video">Video Consultation</option>
          </select>
        </div>
        <br />
        {consultationStatus === 'upcoming' && consultationType === '' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentConsultations.map((consultation) => (
                <tr key={consultation.id}>
                  <td>{consultation.consultationType}</td>
                  <td>{formatDate(consultation.requestedDate)}</td>
                  <td>{consultation.selectedTimeSlot}</td>
                  <td>{consultation.hospitalName}</td>
                  <td>{consultation.doctorName}</td>
                  <td>
                    {consultation.consultationType === 'Video' && (
                      <a href={`/Video/${consultation.consultationId}`} target="_blank" rel="noopener noreferrer">
                        Join Consultation
                      </a>
                    )}
                  </td>
                  <td>
                    <button className="cancel-button" onClick={() => handleCancelConsultation(consultation.consultationId)}>
                      Cancel
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'upcoming' && consultationType === 'Physical' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentConsultations
                .filter((consultation) => consultation.consultationType === 'Physical')
                .map((consultation) => (
                  <tr key={consultation.id}>
                    <td>{consultation.consultationType}</td>
                    <td>{formatDate(consultation.requestedDate)}</td>
                    <td>{consultation.selectedTimeSlot}</td>
                    <td>{consultation.hospitalName}</td>
                    <td>{consultation.doctorName}</td>
                    <td>
                      {consultation.consultationType === 'Video' && (
                        <a href={`/Video/${consultation.consultationId}`} target="_blank" rel="noopener noreferrer">
                          Join Consultation
                        </a>
                      )}
                    </td>
                    <td>
                      <button className="cancel-button" onClick={() => handleCancelConsultation(consultation.consultationId)}>
                        Cancel
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'upcoming' && consultationType === 'Video' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentConsultations
                .filter((consultation) => consultation.consultationType === 'Video')
                .map((consultation) => (
                  <tr key={consultation.id}>
                    <td>{consultation.consultationType}</td>
                    <td>{formatDate(consultation.requestedDate)}</td>
                    <td>{consultation.selectedTimeSlot}</td>
                    <td>{consultation.hospitalName}</td>
                    <td>{consultation.doctorName}</td>
                    <td>
                      {consultation.consultationType === 'Video' && (
                        <a href={`/Video/${consultation.consultationId}`} target="_blank" rel="noopener noreferrer">
                          Join Consultation
                        </a>
                      )}
                    </td>
                    <td>
                      <button className="cancel-button" onClick={() => handleCancelConsultation(consultation.consultationId)}>
                        Cancel
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}








{consultationStatus === 'historic' && consultationType === '' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                
                
              </tr>
            </thead>
            <tbody>
              {currentConsultations.map((consultation) => (
                <tr key={consultation.id}>
                  <td>{consultation.consultationType}</td>
                  <td>{formatDate(consultation.requestedDate)}</td>
                  <td>{consultation.selectedTimeSlot}</td>
                  <td>{consultation.hospitalName}</td>
                  <td>{consultation.doctorName}</td>
                  
                  
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'historic' && consultationType === 'Physical' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                
                
              </tr>
            </thead>
            <tbody>
              {currentConsultations
                .filter((consultation) => consultation.consultationType === 'Physical')
                .map((consultation) => (
                  <tr key={consultation.id}>
                    <td>{consultation.consultationType}</td>
                    <td>{formatDate(consultation.requestedDate)}</td>
                    <td>{consultation.selectedTimeSlot}</td>
                    <td>{consultation.hospitalName}</td>
                    <td>{consultation.doctorName}</td>
                    
                    
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'historic' && consultationType === 'Video' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                
               
              </tr>
            </thead>
            <tbody>
              {currentConsultations
                .filter((consultation) => consultation.consultationType === 'Video')
                .map((consultation) => (
                  <tr key={consultation.id}>
                    <td>{consultation.consultationType}</td>
                    <td>{formatDate(consultation.requestedDate)}</td>
                    <td>{consultation.selectedTimeSlot}</td>
                    <td>{consultation.hospitalName}</td>
                    <td>{consultation.doctorName}</td>
                    
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'cancelled' && consultationType === '' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                
                
              </tr>
            </thead>
            <tbody>
              {currentConsultations.map((consultation) => (
                <tr key={consultation.id}>
                  <td>{consultation.consultationType}</td>
                  <td>{formatDate(consultation.requestedDate)}</td>
                  <td>{consultation.selectedTimeSlot}</td>
                  <td>{consultation.hospitalName}</td>
                  <td>{consultation.doctorName}</td>
                  
                  
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'cancelled' && consultationType === 'Physical' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                
                
              </tr>
            </thead>
            <tbody>
              {currentConsultations
                .filter((consultation) => consultation.consultationType === 'Physical')
                .map((consultation) => (
                  <tr key={consultation.id}>
                    <td>{consultation.consultationType}</td>
                    <td>{formatDate(consultation.requestedDate)}</td>
                    <td>{consultation.selectedTimeSlot}</td>
                    <td>{consultation.hospitalName}</td>
                    <td>{consultation.doctorName}</td>
                    
                    
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {consultationStatus === 'cancelled' && consultationType === 'Video' && (
          <table className="upcoming-consultations-table">
            <thead>
              <tr>
                <th>Consultation Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Hospital Name</th>
                <th>Doctor Name</th>
                
               
              </tr>
            </thead>
            <tbody>
              {currentConsultations
                .filter((consultation) => consultation.consultationType === 'Video')
                .map((consultation) => (
                  <tr key={consultation.id}>
                    <td>{consultation.consultationType}</td>
                    <td>{formatDate(consultation.requestedDate)}</td>
                    <td>{consultation.selectedTimeSlot}</td>
                    <td>{consultation.hospitalName}</td>
                    <td>{consultation.doctorName}</td>
                    
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button key={index + 1} className="pagination-button" onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ConsultationsPage;