import React from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "../styles/LayoutStyles.css";
import { SidebarMenu } from "./../Data/data";
import logoImage from "../images/logoImage.png";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { faker } from '@faker-js/faker';

const Layout = ({ children,notificationDetails,roleName }) => {
  const hasNewNotification = notificationDetails?.some(notification => !notification.isRead);
  const location = useLocation();
  const navigate = useNavigate(); 

  const handleNotificationClick = () => {
    
    navigate("/notifications", { state: { notificationDetails,roleName } });  // Navigate to the notification page
  };
  const { userData } = useSelector((state) => state.auth);
  const userData1 = JSON.parse(localStorage.getItem('userData')) || {};
  const userFirstName = userData1 ?.user?.firstName || 'Guest';
  const randomImageURL = faker.image.avatar();
const userRole=userData?.user?.roleName;

  const handleLogout = () => {
    
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    // Navigate to login page
    navigate('/login');
  };
  const menuData = SidebarMenu(userRole);
  const menu = (
    <Menu>
      <Menu.Item key="ResetPassword">
        <Link to="/ResetPassword">Reset Password</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  );
  

  return (
    <>
      <div className="main">
        <div className="layout">
          <div className="sidebar">
            <div className="logo">
              <div className="logo-container">
                <img src={logoImage} alt="Patient Portal Logo" className="logo-img" />
              </div>
            </div>
            <div className="menu">
            {menuData.map((menu) => {  // Use menuData instead of SidebarMenu
                const isActive = location.pathname === menu.path;
                return (
                  <div key={menu.name} className={`menu-item ${isActive && "active"}`}>
                    <i className={menu.icon}></i>
                    <Link to={menu.path}>{menu.name}</Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="content">
            <div className="header">
              <div className="user-actions">
              <div className="user-avatar">
            <img src={randomImageURL} alt="User Avatar" className="avatar-img" />
          </div>
                <div className="user-welcome">Welcome, {userFirstName}!</div>
                <div className="notification-icon">
                  
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={`bell-icon ${hasNewNotification ? 'has-new' : ''}`}
                    onClick={handleNotificationClick}
                  >
                    <path d="M18 8C18 8 19 12 19 12H5s1-4 1-8h2c0-3.314 1.925-6 4-6s4 2.686 4 6h2c0 4-1 8-1 8z"></path>
                    <path d="M12 23a2 2 0 01-2-2h4a2 2 0 01-2 2z"></path>
                  </svg>
                  {/* Conditionally render the red symbol based on new notifications */}
                  {hasNewNotification && <span className="red-symbol">&#x1F534;</span>}
                </div>


                <div className="user-dropdown">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                       <DownOutlined />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="body">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;