// ResetPassword.js

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { useResetPasswordMutation } from '../redux/features/resetPasswordSlice';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Layout from '../components/Layout';

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [resetPassword] = useResetPasswordMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      
      const response = await resetPassword({ username, currentPassword, newPassword }).unwrap();
      toast.success(response.message);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
    <FormContainer>
      <h1 className="form-header">Reset Password</h1>
      <Form onSubmit={submitHandler} className="form-container">
        <Form.Group controlId="username">
          <Form.Label>UserName</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="currentPassword">
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="newPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Group>

        {isLoading && <Loader />}

        <Button disabled={isLoading} type="submit" variant="primary" className="mt-3">
          Reset Password
        </Button>
      </Form>
    </FormContainer>
    </Layout>
  );
};

export default ResetPassword;
