import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiInstance from './AxiosConfig';
import '../styles/PrescriptionList.css';
import Layout from '../components/Layout';
import moment from 'moment';

const PrescriptionDetails = () => {
    const { consultationId } = useParams();
    const [prescriptions, setPrescriptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [prescriptionsPerPage] = useState(2); // Adjust the number of prescriptions per page as needed

    useEffect(() => {
        const fetchPrescriptions = async () => {
            try {
                const response = await apiInstance.get(`/api/Prescription/GetPrescriptionDetails?ConsultationId=${consultationId}`);

                const modifiedPrescriptions = response.data.map(prescription => ({
                    ...prescription,
                    prescribedDate: moment(prescription.prescribedDate).format("YYYY-MM-DD"),
                    validUntil: moment(prescription.validUntil).format("YYYY-MM-DD")
                }));
                setPrescriptions(modifiedPrescriptions);
            } catch (error) {
                console.error('Error fetching prescriptions:', error);
            }
        };

        fetchPrescriptions();
    }, [consultationId]);

    // Get current prescriptions
    const indexOfLastPrescription = currentPage * prescriptionsPerPage;
    const indexOfFirstPrescription = indexOfLastPrescription - prescriptionsPerPage;
    const currentPrescriptions = prescriptions.slice(indexOfFirstPrescription, indexOfLastPrescription);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <Layout>
            <div className="prescription-list">
                <div className="back-link">
                    <Link to={`/PatientProfileViewByDoctor/${consultationId}`}>&lt; Back to Patient Profile</Link>
                </div>
                <h2>Prescription List</h2>
                <ul className="prescription-items">
                    {currentPrescriptions.map((prescription, index) => (
                        <li key={index} className="prescription-item">
                            <div className="prescription-details">
                                <h3>{prescription.medicineName}</h3>
                                <p><strong>Dosage:</strong> {prescription.dossage}</p>
                                <p><strong>Duration:</strong> {prescription.duration}</p>
                                <p><strong>Area of Use:</strong> {prescription.areaOfUse}</p>
                                <p><strong>Prescribed Date:</strong> {prescription.prescribedDate}</p>
                                <p><strong>Valid Until:</strong> {prescription.validUntil}</p>
                                <p><strong>Notes:</strong> {prescription.notes}</p>
                                <p><strong>Repetition:</strong> {prescription.repetition}</p>
                                <p><strong>Time of the Day:</strong> {prescription.timeOfTheDay}</p>
                                <p><strong>To Be Taken:</strong> {prescription.toBeTaken}</p>
                                <p><strong>Prescribed By:</strong> {prescription.prescribedBy}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                {/* Pagination */}
                {prescriptions.length > prescriptionsPerPage && (
                    <div className="pagination">
                        <ul className="pagination-list">
                            {[...Array(Math.ceil(prescriptions.length / prescriptionsPerPage)).keys()].map(number => (
                                <li key={number}>
                                    <button onClick={() => paginate(number + 1)} className={currentPage === number + 1 ? 'active' : ''}>
                                        {number + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default PrescriptionDetails;
