import React, { useState ,useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/CardDetails.css'; 
import Layout from '../components/Layout';
import apiInstance from './AxiosConfig';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';

function CardDetails() {
    const [formData, setFormData] = useState({
        cardNumber: '',
        cardName: '',
        cvv: '',
        expiryDate: null
    });
    const [formErrors, setFormErrors] = useState({
        cardNumber: '',
        cardName: '',
        cvv: '',
        expiryDate: ''
    });
    const navigate = useNavigate();

    const { userData } = useSelector((state) => state.auth);
    const userId = userData?.user?.id;


    useEffect(() => {
        const fetchCardDetails = async () => {
            try {
                const response = await apiInstance.get(`/api/Patient/GetCardDetails?UserId=${userId}`);
                if (response && response.data) {
                    const cardDetails = response.data[0]; 
                    setFormData({
                        cardNumber: cardDetails.cardNumber,
                        cardName: cardDetails.cardName,
                        cvv: cardDetails.cvv,
                        expiryDate: new Date(cardDetails.expiryDate)
                    });
                }
            } catch (error) {
                console.error(error);
                
            }
        };

        if (userId) {
            fetchCardDetails();
        }
    }, [userId]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        setFormData(prevState => ({
            ...prevState,
            expiryDate: date
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await apiInstance.post('/api/Patient/SaveCardDetails', {
                    userId: userId,
                    ...formData
                });
                
                console.log(response.data);
                if (response && response.data && response.data.message) {
                    if (response.data.message === 'Success') {
                alert('Card details saved successfully');
                navigate("/");


                
                    }}
            } catch (error) {
                console.error(error);
                toast.error('Error in saving card details');
            }
        } else {
            toast.error('All fields are required');
        }
    };

    const validateForm = () => {
        let valid = true;
        const errors = {
            cardNumber: '',
            cardName: '',
            cvv: '',
            expiryDate: ''
        };

        if (!formData.cardNumber.trim() || !/^\d{10}$/.test(formData.cardNumber.trim())) {
            errors.cardNumber = 'Card number must be a 10-digit number';
            valid = false;
        }
        if (!formData.cardName.trim()) {
            errors.cardName = 'Name on card is required';
            valid = false;
        }
        if (!formData.cvv.trim() || !/^\d{3}$/.test(formData.cvv.trim())) {
            errors.cvv = 'CVV must be a 3-digit number';
            valid = false;
        }
        if (!formData.expiryDate || formData.expiryDate <= new Date()) {
            errors.expiryDate = 'Expiry date must be greater than the current date';
            valid = false;
        }

        setFormErrors(errors);
        return valid;
    };

    return (
        <Layout>
            <div className="center-container">
                <div className="card-details-container">
                    <h1>Enter Card Details</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="cardNumber">Card Number:</label>
                        <input type="text" id="cardNumber" name="cardNumber" value={formData.cardNumber} onChange={handleChange} required />
                        {formErrors.cardNumber && <span className="error">{formErrors.cardNumber}</span>}

                        <label htmlFor="cardName">Name on Card:</label>
                        <input type="text" id="cardName" name="cardName" value={formData.cardName} onChange={handleChange} required />
                        {formErrors.cardName && <span className="error">{formErrors.cardName}</span>}

                        <label htmlFor="cvv">CVV:</label>
                        <input type="text" id="cvv" name="cvv" value={formData.cvv} onChange={handleChange} minLength="3" maxLength="4" required />
                        {formErrors.cvv && <span className="error">{formErrors.cvv}</span>}

                        <label htmlFor="expiryDate">Expiry Date:</label>
                        <DatePicker 
                            id="expiryDate" 
                            name="expiryDate" 
                            selected={formData.expiryDate}
                            onChange={handleDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM/DD/YYYY"
                            className="form-control"
                            required
                        />
                        {formErrors.expiryDate && <span className="error">{formErrors.expiryDate}</span>}

                        <input type="submit" value="Submit" />
                    </form>
                </div>
                <ToastContainer />
            </div>
        </Layout>
    );
}

export default CardDetails;
