import { apiSlice } from "./apiSlice";

const USERS_URL = '/api/DoctorList';
 const getAccessTokenFromLocalStorage = () => {
  const userDataString = localStorage.getItem("userData");
   const userData = userDataString ? JSON.parse(userDataString) : null;
   console.log("userData",userData);
   const token =  userData?.token ;
   console.log("Retrieved Token:", token);
  return token;
};

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({



    getDoctorsList: builder.query({
        query: (adminId) => `${USERS_URL}?adminId=${adminId}`,
        
      }),
      

   
     getDoctorById: builder.query({
      query: (doctorId) => `${USERS_URL}/Get?doctorId=${doctorId}`,
      
      
    }),

    updateDoctor: builder.mutation({
        query: ({ data }) => ({
          url:`${USERS_URL}/Put`, 
          method:'PUT',
          body: data,
          
          
        }),
      }),




   
    
     
     
  }),
});

export const {useGetDoctorsListQuery, useGetDoctorByIdQuery,useUpdateDoctorMutation} = usersApiSlice;