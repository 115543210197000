import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import PatientListPage from "./PatientList";

import apiInstance from './AxiosConfig';


const DoctorHomePage = () => {
  const [notificationDetails, setNotificationDetails] = useState([]);
  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const UserId = userData?.user?.id;
  const roleName=userData?.user?.roleName;

  // Use the new hook for fetching doctor notification details
 
  useEffect(() => {
    if (UserId) {
      // Fetch notification details
      apiInstance.get(`/api/Notification/GetNotification?UserId=${UserId}`)
        .then(response => {
          // Set notification details in state
          setNotificationDetails(response.data);
        })
        .catch(error => {
          console.error("Error fetching notifications:", error);
        });
    }
  }, [UserId]);

  
  console.log(notificationDetails);

  return (
    
   
      
    <Layout  roleName={roleName} notificationDetails={notificationDetails}>
      
        
         
         <PatientListPage />
      </Layout>
    
     
  
  );
};

export default DoctorHomePage;
