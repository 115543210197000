import React, { useState, useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import axios from "axios";
import apiInstance from "./AxiosConfig";

import Layout from "../components/Layout";
import { faker } from "@faker-js/faker";
import '../styles/Chat.css';

const AssignedDoctorDetails = () => {
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [doctorImage, setDoctorImage] = useState("");
  const [chatSrc, setChatSrc] = useState("");

  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const token = userData?.token;
  const UserId = userData?.user?.id;
  const name = userData?.user?.firstName;
  const baseURLForChat = 'https://signalrchatapphima.azurewebsites.net/';
  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        const response = await apiInstance.get(
          `/api/Patient/GetAssignedDoctorDetails?UserId=${UserId}`
        );
        const doctor = response.data[0];

        // Set the doctor details
        setDoctorDetails(doctor);

        // Set the doctor image only if it's not already set
        if (!doctorImage) {
          setDoctorImage(faker.image.avatar());
        }
      } catch (error) {
        console.error("Error fetching doctor details:", error);
        setError("No assigned Doctor at the moment.");
      } finally {
        setLoading(false);
      }
    };

    fetchDoctorDetails();
  }, [UserId]);

  const handleChatButtonClick = () => {
    // Set the chat iframe src when chat button is clicked
    setChatSrc(
      `${baseURLForChat}?userId=${UserId}&token=${token}&doctorId=${doctorDetails.doctorId}&firstName=${name}&receiverName=${doctorDetails.firstName}`
    );
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Assigned Doctor Details</h1>
    <div style={{ marginTop: "100px", width: "100%" }}>
      <Container style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ flex: "1", maxWidth: "80%" }}>
          {loading && <p>Loading...</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          {doctorDetails ? (
                <Card>
                  <Card.Img
                    src={doctorImage}
                    alt="Doctor Avatar"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <Card.Body>
                    <Card.Title>
                      {doctorDetails.firstName} {doctorDetails.lastName}
                    </Card.Title>
                    <Card.Text>
                      PhoneNumber: {doctorDetails.phoneNumber}
                    </Card.Text>
                    <Card.Text>Email: {doctorDetails.email}</Card.Text>
                    <Card.Text>
                      HospitalName: {doctorDetails.hospitalName}
                    </Card.Text>
                    <Card.Text>
                      Specialization: {doctorDetails.specialization}
                    </Card.Text>
                    <Card.Text>
                      Experience: {doctorDetails.experience}
                    </Card.Text>
                    <Card.Text>
                      Qualification: {doctorDetails.qualification}
                    </Card.Text>

                    {/* Chat link */}
                    <button onClick={handleChatButtonClick}>Chat</button>

                    {/* Iframe for embedding chat */}
                    <iframe
                      id="chatFrame"
                      src={chatSrc}
                      className="chat-frame"
                      style={{
                        display: chatSrc ? "block" : "none",
                      }}
                    ></iframe>
                  </Card.Body>
                </Card>
              ) : (
                <p>No assigned doctor at the moment.</p>
              )}
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default AssignedDoctorDetails;
