import { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRegisterMutation } from '../redux/features/usersApiSlice';
import { setCredentials } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import '../styles/RegisterStyles.css';


const RegisterScreen = () => {
  const [username, setUsername] = useState('');
 
  const [firstname, setFirstname] = useState('');
  
  const [lastname, setLastname] = useState('');
  
  const [email, setEmail] = useState('');
  
  const [password, setPassword] = useState('');
  
  const [confirmpassword, setConfirmpassword] = useState('');

  const[phonenumber,setPhonenumber]=useState('');
  
  const[streetaddress,setStreetaddress]=useState('');
  

  const[city,setCity]=useState('');
  

  const[country,setCountry]=useState('');
 

  const[postalcode,setPostalcode]=useState('');
  
  const[dateofbirth,setDateofbirth]=useState('');
  

  const[gender,setGender]=useState('');
  
  const[height,setHeight]=useState('');
  

  const[weight,setWeight]=useState('');
  

  const[personalnumber,setPersonalnumber]=useState('');
  
  

  const dispatch = useDispatch();
const navigate = useNavigate();

const [register, { isLoading }] = useRegisterMutation();

const { userData } = useSelector((state) => state.auth);



  const submitHandler = async (e) => {
  e.preventDefault();
  


  if (password != confirmpassword) {
    toast.error('Passwords do not match');
  }
  if(!username || !firstname || !lastname || !email || !password || !confirmpassword || !phonenumber || !streetaddress || !city || !country || !postalcode || !dateofbirth || !gender || !height || !weight || !personalnumber){
    toast.error('All fields are required');
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    toast.error('Invalid email format');}
  
  
    try {
      const res = await register({ phonenumber,username, firstname,lastname,email, password,confirmpassword,streetaddress,city,country,postalcode,dateofbirth,gender,height,weight,personalnumber }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate('/login');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  
};

  return (
    <div className="imageholder">
      {/* Background image */}
      <div className="background-image"></div>
    <FormContainer>
      <h1>Register</h1>
      <Form onSubmit={submitHandler}>

       
      <Form.Group className='my-2' controlId='username'>
          <Form.Label>UserName</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>


        
        <Form.Group className='my-2' controlId='firstname'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Firstname'
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>


        <Form.Group className='my-2' controlId='lastname'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Lastname'
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='phonenumber'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='tel' pattern="[0-9]{10}"
            placeholder='Enter phone number'
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          ></Form.Control>
        </Form.Group>
         

        <Form.Group className='my-2' controlId='streetaddress'>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Street Address'
            value={streetaddress}
            onChange={(e) => setStreetaddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>

        </Form.Group>

        <Form.Group className='my-2' controlId='postalcode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Postal Code'
            value={postalcode}
            onChange={(e) => setPostalcode(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='dateofbirth'>
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter Date Of Birth'
            value={dateofbirth}
            onChange={(e) => setDateofbirth(e.target.value)}
          ></Form.Control>
        </Form.Group>




        <Form.Group className='my-2' controlId='gender'>
        <Form.Label>Gender</Form.Label>
        <Form.Control
          as='select'
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </Form.Control>
      </Form.Group>


        <Form.Group className='my-2' controlId='height'>
          <Form.Label>Height</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter Height'
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='weight'>
          <Form.Label>Weight</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter weight'
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='personalnumber'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Personal number'
            value={personalnumber}
            onChange={(e) => setPersonalnumber(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='confirmpassword'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Confirm Password'
            value={confirmpassword}
            onChange={(e) => setConfirmpassword(e.target.value)}
          ></Form.Control>
        </Form.Group>


        
        
        {
     isLoading && <Loader />
        }
        <Button type='submit' variant='primary' className='mt-3'  >
          Register
        </Button>
      </Form>

      <Row className='py-3'>
        <Col>
          Already have an account? <Link to={`/login`}>Login</Link>
        </Col>
      </Row>
    </FormContainer>
    </div>
  );
};

export default RegisterScreen;