import { apiSlice } from "./apiSlice";

const USERS_URL = '/api/Profile';
 const getAccessTokenFromLocalStorage = () => {
  const userDataString = localStorage.getItem("userData");
   const userData = userDataString ? JSON.parse(userDataString) : null;
   console.log("userData",userData);
   const token =  userData?.token ;
   console.log("Retrieved Token:", token);
  return token;
};

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({


             
    getAdminById: builder.query({
      query: (UserId) => `${USERS_URL}/Profile?UserId=${UserId}`,
            
    }),
    updateAdmin: builder.mutation({
        query: ({ data }) => ({
          url:`${USERS_URL}/Put`, 
          method:'PUT',
          body: data,
          
        }),
      }),


    getPatientById: builder.query({
      query: (UserId) =>  `${USERS_URL}/PatientProfile?UserId=${UserId}`,
          
    }),


    updatePatient: builder.mutation({
        query: ({ data }) => ({
          url:`${USERS_URL}/PatientProfileUpdate`, 
          method:'PUT',
          body: data,
          
                   
        }),
      }),



    getDoctordetailsById: builder.query({
      query: (UserId) =>  `${USERS_URL}/DoctorProfile?UserId=${UserId}`,
          
    }),



    

      


     


     

      updateDoctorDetails: builder.mutation({
        query: ({ data }) => ({
          url:`${USERS_URL}/DoctorProfileUpdate`, 
          method:'PUT',
          body: data,
                   
        }),
      }),






   
    
     
     
  }),
});

export const {useGetAdminByIdQuery,useUpdateAdminMutation,useGetPatientByIdQuery,useUpdatePatientMutation,useGetDoctordetailsByIdQuery,useUpdateDoctorDetailsMutation,useGetNotificationDetailsByIdQuery} = usersApiSlice;