import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import apiInstance from './AxiosConfig';
import Layout from '../components/Layout';
import '../styles/ConsultationBooking.css';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import doctorAppointmentImage from '../images/bookingimage.png';

const ConsultationBookingPage = () => {
  const [hospitalName, setHospitalName] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [requestedDate, setRequestedDate] = useState(new Date());
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [comments, setComments] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const UserId = userData?.user?.id;
  const [consultationType, setConsultationType] = useState('');
  const [roomLink, setRoomLink] = useState('');
  const navigate = useNavigate();

  const generateRoomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 5; // You can adjust the length of the room ID as needed
    let roomId = '';
    for (let i = 0; i < length; i++) {
      roomId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return roomId;
  };
  // Fetch Hospital and Doctor Details
  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        const response = await apiInstance.get(`/api/Patient/GetAssignedDoctorDetails?UserId=${UserId}`);
        const doctor = response.data[0];
        setDoctorDetails(doctor);
        setHospitalName(doctor.hospitalName);
        setDoctorName(`${doctor.firstName} ${doctor.lastName}`);
      } catch (error) {
        console.error('Error fetching doctor details:', error.message);
      }
    };

    fetchDoctorDetails();
  }, [UserId]);

  // Fetch available dates for booking
  const fetchAvailableDates = async () => {
    try {
      if (doctorDetails && doctorDetails.doctorId) {
        const apiUrl = `/api/Doctor/GetAvailableDatesforbooking?DoctorId=${doctorDetails.doctorId}`;
        const response = await apiInstance.get(apiUrl);
        if (response.data && response.data.length > 0) {
          setAvailableDates(response.data);
        }
      }
    } catch (error) {
      console.error('Error fetching available dates:', error.message);
    }
  };

  useEffect(() => {
    if (showCalendar) {
      fetchAvailableDates();
    }
  }, [doctorDetails, showCalendar]);

  // Handle click on calendar icon
  const handleShowCalendar = async () => {
    // Fetch available dates when calendar is shown
    await fetchAvailableDates();

    setShowCalendar(true);
  };
  const fetchConsultationFees = async (consultationType) => {
    try {
      // Make API call to fetch consultation fees using consultationId
      // Replace '/api/Consultation/GetConsultationFees' with your actual API endpoint
      const response = await apiInstance.get(`/api/Patient/GetConsultationFeesDetails?consultationType=${consultationType}`);
      return response.data.amount; // Assuming the response contains consultation fees
    } catch (error) {
      console.error('Error fetching consultation fees:', error.message);
      return null;
    }
  };
  // Handle Confirm Booking
  // Handle Confirm Booking
const handleConfirmBooking = async () => {
  const doctorId = doctorDetails.doctorId;

  try {
    const requestData = {
      UserId,
      doctorId,
      requestedDate,
      selectedTimeSlot,
      comments,
      consultationType
    };
    const apiUrl = '/api/Patient/SaveConsultationBookingDetails';

    if (consultationType === 'Video') {
      // Generate room ID and create link only for video consultation
      const roomId = generateRoomId();
      const link = `https://doctorpatient.no/room/${roomId}`;
      setRoomLink(link);
      
      requestData.roomLink = link; // Pass room link only for video consultation
    }

    const response = await apiInstance.post(apiUrl, requestData);

    if (response && response.status === 200) {
      if (response.data && response.data.message === 'Success') {
        const { doctorName, patientName, consultationId } = response.data;
        const consultationFees = await fetchConsultationFees(consultationType);
        console.log("ConsultationFees", consultationFees);
        localStorage.setItem('paymentpending', JSON.stringify(true));

        navigate("/payment", {
          state: {
            doctorName,
            patientName,
            consultationId,
            consultationFees,
            requestedDate,
            selectedTimeSlot,
            consultationType
          }
        });
      } else {
        toast.error('Failed To Book: ' + response.data.message);
      }
    } else {
      // Handle the case when the response structure is not as expected
      toast.error('Failed To Book: Unexpected response structure');
    }
  } catch (error) {
    console.error('Error saving availability:', error.message);
  }
};

  return (
    <Layout>
      <div>
        {/* First Row */}
        <div className="first-row">
          <img src={doctorAppointmentImage} alt="Consultation" />
        </div>

        <div>
          {/* Second Row */}
          <div className="second-row">
            {/* First Column */}
            <div className="column">
              <div>
                <h1>Hospital Info</h1>
                <label>Hospital Name:</label>
                <input type="text" value={hospitalName} readOnly />
              </div>
              <div>
                <label>Doctor Name:</label>
                <input type="text" value={doctorName} readOnly />
              </div>
              <div>
                <label>Type of Consultation:</label>
                <select onChange={(e) => setConsultationType(e.target.value)}>
                <option value="">Select Consultation Type</option>
  <option value="Physical">Physical consultation</option>
  <option value="Video">Video consultation</option>
</select>

              </div>
            </div>

            {/* Second Column */}
            <div className="column">
              <div>
                <label>Requested Date:</label>
                <input type="text" value={requestedDate.toLocaleDateString()} readOnly />
                {/* Calendar icon to show available dates */}
                <FontAwesomeIcon icon={faCalendar} onClick={handleShowCalendar} />
                {/* Calendar component */}
                {showCalendar && (
                  <Calendar
                    onChange={(date) => {
                      if (date >= new Date()) {
                        setRequestedDate(date);
                      }
                      setShowCalendar(false);
                    }}
                    value={requestedDate}
                    tileClassName={({ date }) =>
                      date >= new Date() && availableDates.some(availableDate => availableDate.workingDate.split('T')[0] === date.toISOString().split('T')[0])
                        ? 'working-date'
                        : 'disabled-date'
                    }
                    tileDisabled={({ date }) => {
                        const isBeforeToday = date < new Date();
                        const isAvailableDate = availableDates.some(
                          (availableDate) =>
                            availableDate.workingDate.split('T')[0] === date.toISOString().split('T')[0]
                        );
                      
                        return isBeforeToday || !isAvailableDate;
                      }}
                      
                  />
                )}
              </div>
              <div>
              <label>Time Slots:</label>
<select onChange={(e) => setSelectedTimeSlot(e.target.value)}>
  {/* Empty field */}
  <option value="">Select Time Slot</option>
  {/* Display time slots for the selected date */}
  {availableDates
    .filter(availableDate => availableDate.workingDate.split('T')[0] === requestedDate.toISOString().split('T')[0])
    .map((timeSlot) => (
      <option key={timeSlot.from + timeSlot.to} value={timeSlot.from}>
        {`${timeSlot.from} - ${timeSlot.to}`}
      </option>
    ))}
</select>

              </div>
            </div>

            {/* Third Column */}
            <div className="column">
              <div>
                <label>Comments:</label>
                <textarea value={comments} onChange={(e) => setComments(e.target.value)} />
              </div>
            </div>
          </div>

          {/* Third Row */}
          <button className="confirm-button" onClick={handleConfirmBooking}>Confirm</button>
        </div>
      </div>
    </Layout>
  );
};

export default ConsultationBookingPage;