import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import Loader from '../components/Loader';
import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Card, Form, Button, Pagination } from 'react-bootstrap';
import { faker } from '@faker-js/faker';
import '../styles/Patientcard.css';
import apiInstance from './AxiosConfig';
import '../styles/Chat.css';

const PatientListPage = () => {
  const { userData } = useSelector((state) => state.auth);
  const userId = userData?.user?.id;
  const token = userData?.token;
  const name = userData?.user?.firstName;

  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [chatSrcMap, setChatSrcMap] = useState({}); // Store chat source for each patient ID
  const patientsPerPage = 3;


  const baseURLForChat = 'https://signalrchatapphima.azurewebsites.net/';
  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await apiInstance.get(`/api/DoctorList/GetPatientList?UserId=${userId}`);
        setPatients(response.data);
        setFilteredPatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
        toast.error('No patients are assigned yet');
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, [userId, token]);

  const handleSearch = () => {
    const filtered = patients.filter(
      (patient) =>
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPatients(filtered);
  };

  const handleChatButtonClick = (patientId, receiverName) => {
    // Update chat source for the specific patient ID
    setChatSrcMap({
      ...chatSrcMap,
      [patientId]: `${baseURLForChat}?userId=${userId}&token=${token}&patientId=${patientId}&firstName=${name}&receiverName=${receiverName}`
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  return (
    
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <h1>Patient List</h1>
            <div className="mb-4">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Search by name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" onClick={handleSearch}>
                  Search
                </Button>
              </Form>
            </div>
            <Row xs={1} md={2} lg={3} className="g-4">
              {currentPatients.map((patient, index) => (
                <Col key={index} className="mb-4">
                  <Card className="patient-card">
                    <Card.Body>
                      <Avatar
                        name={`${patient.firstName} ${patient.lastName}`}
                        round={true}
                        size="50"
                        src={faker.image.avatar()}
                      />
                      <h5 className="mt-3">{`${patient.firstName} ${patient.lastName}`}</h5>
                      <p>Date of Birth: {new Date(patient.dateOfBirth).toLocaleDateString()}</p>
                      <p>Personal Number: {patient.personalNumber}</p>
                      <p>Phone Number: {patient.phoneNumber}</p>
                      <p>Gender: {patient.gender}</p>
                      <p>
                        Address: {`${patient.streetAddress}, ${patient.city}, ${patient.postalCode}, ${patient.country}`}
                      </p>
                      <p>Height: {patient.height}</p>
                      <p>Weight: {patient.weight}</p>
                      <button onClick={() => handleChatButtonClick(patient.id, patient.firstName)}>Chat</button>
                      <iframe
                        id={`chatFrame_${patient.id}`}
                        src={chatSrcMap[patient.id]} 
                        className="chat-frame"
                        style={{
                         
                      display: chatSrcMap[patient.id] ? 'block' : 'none',
                      
                      
                          
                        }}
                        
                      ></iframe>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <Pagination className="mt-3">
              {[...Array(Math.ceil(filteredPatients.length / patientsPerPage)).keys()].map((number) => (
                <Pagination.Item
                  key={number + 1}
                  active={number + 1 === currentPage}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}
      </div>
    
  );
};

export default PatientListPage;
