// data.js

export const SidebarMenu = (userRole) => {
  switch (userRole) {
    case "Admin":
      return [
        {
          name: "Home",
          path: "/",
          icon: "fa-solid fa-house",
        },
        {
          name: "Add Doctor",
          path: "/addDoctor",
          icon: "fa-solid fa-user-plus",
        },
        {
          name: "Profile",
          path: "/profile",
          icon: "fa-solid fa-user",
        },
        
      ];
    case "Patient":
      return [

        {
          name: "Home",
          path: "/",
          icon: "fa-solid fa-house",
        },
        
        {
          name: "Booking Consultations",
          path: "/consultationBooking",
          icon: "fa-solid fa-calendar",
        },
        {
          name: "Profile",
          path: "/patientProfile",
          icon: "fa-solid fa-user",
        },
        {
          name: "Assign/Change Doctor",
          path: "/assignOrChangeDoctor",
          icon: "fa-solid fa-stethoscope", 
        },

        {
          name: "Assigned Doctor",
          path: "/assignedDoctorDetails",
          icon: "fa-solid fa-stethoscope", 
        },


        {
          name: " Consultations",
          path: "/Consultations",
          icon: "fa-solid fa-clock", 
        },
        {
          name: "Prescriptions",
          path: "/PatientPrescriptions",
          icon: "fa-solid fa-file-prescription",
        },


        {
          name: "Card Details",
          path: "/enterCardDetails",
          icon: "fa-solid fa-credit-card",
      },
      
        
      ];
    case "Doctor":
      return [
        
        {
          name: "Home",
          path: "/",
          icon: "fa-solid fa-house",
        },
        {
          name: "Profile",
          path: "/DoctorProfile",
          icon: "fa-solid fa-user",
        },
        
        {
          name: "Availability",
          path: "/doctorAvailability",
          icon: "fa-solid fa-clock",
        },


        {
          name: "Appointments",
          path: "/doctorAppointments", 
          icon: "fa-solid fa-calendar", 
        },

        
        
      ];
    default:
      return [];
  }
};
