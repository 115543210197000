import React from "react";
import axios from "axios";
import Layout from "./../components/Layout";
import DoctorsList from "./DoctorsList";
const HomePage = () => {
  // login user data
  
 
  return (
    <Layout>
     <h1></h1>
     <DoctorsList/>
    </Layout>
  );
};

export default HomePage;
