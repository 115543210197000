// login.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../redux/features/usersApiSlice';
import { setCredentials } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import '../styles/LoginStyles.css';

const LoginScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ username, password }).unwrap();
      console.log("response...........",res);
      dispatch(setCredentials({ ...res }));

    console.log("",res.otp);
      if(res.otp== 'NOOTP')
      { 
        window.location.replace('/');
      

      }
      else{
        navigate('/otpdetails', { state: { username: username } });
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="bckground-image">
      <FormContainer className="formhead">
        {/* Header inside the form */}
        <div className="form-header-container">
          <h1 className="form-header">SIGN IN</h1>
          
        </div>

        <Form onSubmit={submitHandler} className="form-container">
          <Form.Group controlId="username">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          {isLoading && <Loader />}
          
          <Button disabled={isLoading} type="submit" variant="primary" className="mt-3">
            Sign In
          </Button>
        </Form>

        <div className='py-3'>
          <Link to='/Patientregister' className="register-link">New user? Register</Link>
          <Link to="/hospitalregister" className="register-link">New user? HospitalRegister</Link>
        </div>
        <div>
  <Link to="/forgotpassword" className="forgot-password-link">Forgot Password?</Link>
</div>
      </FormContainer>
    </div>
  );
};

export default LoginScreen;
