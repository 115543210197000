

import React, { useState, useEffect } from 'react';
import { Form, Button,FormCheck } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAdminByIdQuery, useUpdateAdminMutation } from '../redux/features/profileSlice';
import Loader from '../components/Loader';
import Layout from './../components/Layout';
import { setCredentials } from '../redux/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ProfileScreen = () => {
  const { userData } = useSelector((state) => state.auth);
  const UserId=userData?.user?.id;
  
  const navigate = useNavigate();
  const { data: admin, isLoading } = useGetAdminByIdQuery(UserId);
  const [updateAdmin, { isLoading: isUpdating }] = useUpdateAdminMutation();
 
 

   console.log("adminvalues",admin);
  // Individual state for each form field
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [hospitalName, setHospitalName] = useState('');
  const [personalNumber, setPersonalNumber] = useState('');
  const [twoFactorAuth, setTwoFactorAuth] = useState(false); 
  
  useEffect(() => {
    console.log("useEffect triggered", admin);
    if (Array.isArray(admin) && admin.length > 0) {
      console.log("admin values", admin[0]);
      const firstadmin = admin[0];
      
      
      setUsername(firstadmin?.username || '');
      setFirstName(firstadmin?.firstName || '');
      setLastName(firstadmin?.lastName || '');
      setEmail(firstadmin?.email || '');
      setPhoneNumber(firstadmin?.phoneNumber || '');
      setStreetAddress(firstadmin?.streetAddress || '');
      setCity(firstadmin?.city || '');
      setCountry(firstadmin?.country || '');
      setPostalCode(firstadmin?.postalCode || '');
      setDateOfBirth(firstadmin?.dateOfBirth || '');
      setPersonalNumber(firstadmin?.personalNumber || '');
      setHospitalName(firstadmin?.hospitalName || '');
      setTwoFactorAuth(firstadmin?.twofactorAuth || false);
      
    }
  }, [admin]);
  

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
        
      // Make an API call to update the doctor
      await updateAdmin( {
        
        data:{
          UserId,
        username,
        firstName,
        lastName,
        email,
        phoneNumber,
        streetAddress,
        city,
        country,
        postalCode,
        dateOfBirth,
        hospitalName,
        personalNumber,
        twoFactorAuth, 
       
        
    },
       }
      ).unwrap();

      // Redirect to the doctor list page after a successful update
      toast.success('Profile updated successfully');
      
    } catch (error) {
      console.error('Error updating doctor:', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <FormContainer>
        <h1>Your Profile</h1>
        <Form onSubmit={submitHandler}>
         
          <Form.Group className='my-2' controlId='username'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='firstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Firstname'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='lastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Lastname'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='my-2' controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>


          <Form.Group className='my-2' controlId='phoneNumber'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='tel'  pattern="[0-9]{10}"
            
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>
         

        <Form.Group className='my-2' controlId='streetAddress'>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Street Address'
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>

        </Form.Group>

        <Form.Group className='my-2' controlId='postalCode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Postal Code'
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='dateOfBirth'>
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter Date Of Birth'
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          ></Form.Control>
        </Form.Group>
        

        

        <Form.Group className='my-2' controlId='personalNumber'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Personal number'
            value={personalNumber}
            onChange={(e) => setPersonalNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='hospitalName'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Hospital Name'
            value={hospitalName}
            onChange={(e) => setHospitalName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2'>
            <FormCheck
              type='checkbox'
              id='twoFactorAuthCheckbox'
              label='Enable Two-Factor Authentication'
              checked={twoFactorAuth}
              onChange={(e) => setTwoFactorAuth(e.target.checked)}
            />
          </Form.Group>

        
        

          

          <Button type='submit' variant='primary' className='mt-3' disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update'}
          </Button>
        </Form>
      </FormContainer>
    </Layout>
  );
};

export default ProfileScreen;
