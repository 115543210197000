import React, { useState, lazy, Suspense } from 'react';
import Layout from '../components/Layout';
import '../styles/PrescriptionPage.css';
import { useParams, useNavigate, Link } from 'react-router-dom';
import apiInstance from './AxiosConfig';
import { toast } from 'react-toastify';

// Lazy load the DatePicker component
const DatePicker = lazy(() => import('react-datepicker'));

const PrescriptionPage = () => {
    const navigate = useNavigate();
    const { consultationId } = useParams();

    const [prescriptionData, setPrescriptionData] = useState({
        medicineName: '',
        dossage: '',
        areaOfUse: '',
        repetition: 'Everyday',
        timeOfTheDay: 'Morning',
        duration: '',
        prescribedBy: '',
        prescribedDate: null,
        validUntil: null,
        toBeTaken: 'BeforeFood',
        notes: '',
    });
    const [validationError, setValidationError] = useState('');

    const handleInputChange = (field, value) => {
        setPrescriptionData({
            ...prescriptionData,
            [field]: value,
        });
    };

    const handleAddPrescription = async () => {
        try {
            console.log(consultationId);
            if (
                !prescriptionData.medicineName ||
                !prescriptionData.dossage ||
                !prescriptionData.duration ||
                !prescriptionData.prescribedBy ||
                !prescriptionData.prescribedDate ||
                !prescriptionData.validUntil
            ) {
                setValidationError('These fields are required');
                return;
            }

            if (prescriptionData.validUntil <= prescriptionData.prescribedDate) {
                setValidationError('Valid Until date must be greater than Prescribed Date');
                return;
            }

            
            const response = await apiInstance.post('/api/Doctor/AddPrescriptionDetails', {
                consultationId,
                ...prescriptionData,
            });

            if (response && response.status === 200) {
                if (response.data && response.data.message === 'Success') {
                    toast.success('Prescription added successfully');
                    setPrescriptionData({
                        medicineName: '',
                        dossage: '',
                        areaOfUse: '',
                        repetition: 'Everyday',
                        timeOfTheDay: 'Morning',
                        duration: '',
                        prescribedBy: '',
                        prescribedDate: null,
                        validUntil: null,
                        toBeTaken: 'BeforeFood',
                        notes: '',
                    });
                    setValidationError('');
                } else {
                    toast.error('Failed to add prescription: ' + response.data.message);
                }
            }
        } catch (error) {
            console.error('Error adding prescription:', error.message);
            toast.error('Failed to add prescription. Please try again later.');
        }
    };

    return (
        <Layout>
            <div className="prescription-container">
                <div className="link-container">
                    <Link to={`/appointment-details/${consultationId}`} className="appointment-details-link">
                        Back to Appointment Details
                    </Link>
                </div>
                <h2>Add Prescription</h2>
                {validationError && <div className="validation-error">{validationError}</div>}
                <div className="prescription-form">
                    <div className="form-group">
                        <label htmlFor="medicineName">Medicine Name:</label>
                        <input
                            type="text"
                            id="medicineName"
                            value={prescriptionData.medicineName}
                            onChange={(e) => handleInputChange('medicineName', e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="dosssage">Dossage:</label>
                        <input
                            type="text"
                            id="dosage"
                            value={prescriptionData.dosage}
                            onChange={(e) => handleInputChange('dossage', e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="areaOfUse">Area of Application:</label>
                        <input
                            type="text"
                            id="areaOfUse"
                            value={prescriptionData.areaOfUse}
                            onChange={(e) => handleInputChange('areaOfUse', e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="repetition">Repeat:</label>
                        <select
                            id="repetition"
                            value={prescriptionData.repetition}
                            onChange={(e) => handleInputChange('repetition', e.target.value)}
                        >
                            <option value="Everyday">Everyday</option>
                            <option value="AlternateDays">Alternate Days</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="timeOfTheDay">Time:</label>
                        <select
                            id="timeOfTheDay"
                            value={prescriptionData.timeOfTheDay}
                            onChange={(e) => handleInputChange('timeOfTheDay', e.target.value)}
                        >
                            <option value="Morning">Morning</option>
                            <option value="Noon">Noon</option>
                            <option value="Evening">Evening</option>
                            <option value="Night">Night</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="duration">Duration:</label>
                        <input
                            type="text"
                            id="duration"
                            value={prescriptionData.duration}
                            onChange={(e) => handleInputChange('duration', e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="prescribedBy">Prescribed By:</label>
                        <input
                            type="text"
                            id="prescribedBy"
                            value={prescriptionData.prescribedBy}
                            onChange={(e) => handleInputChange('prescribedBy', e.target.value)}
                        />
                    </div>

                    <Suspense fallback={<div>Loading...</div>}>
                        <div className="form-group">
                            <label htmlFor="date">Date:</label>
                            <DatePicker
                                id="date"
                                selected={prescriptionData.prescribedDate}
                                onChange={(date) => handleInputChange('prescribedDate', date)}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="validUntil">Valid Until:</label>
                            <DatePicker
                                id="validUntil"
                                selected={prescriptionData.validUntil}
                                onChange={(date) => handleInputChange('validUntil', date)}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                            />
                        </div>
                    </Suspense>

                    <div className="form-group">
                        <label htmlFor="toBeTaken">To be Taken:</label>
                        <select
                            id="toBeTaken"
                            value={prescriptionData.toBeTaken}
                            onChange={(e) => handleInputChange('toBeTaken', e.target.value)}
                        >
                            <option value="BeforeFood">Before Food</option>
                            <option value="AfterFood">After Food</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="notes">Doctor's Note:</label>
                        <textarea
                            id="notes"
                            value={prescriptionData.notes}
                            onChange={(e) => handleInputChange('notes', e.target.value)}
                            rows={4} // Set the number of rows as needed
                        />
                    </div>

                    <button className="add-prescription-button" onClick={handleAddPrescription}>
                        Add Prescription
                    </button>
                </div>
            </div>
        </Layout>
    );
};

export default PrescriptionPage;
