import React, { useState, useEffect } from 'react';
import apiInstance from './AxiosConfig';
import '../styles/DoctorAppointmentpage.css';
import { useSelector } from 'react-redux';
import Layout from '../components/Layout';
import { format } from 'date-fns';
import { faker } from '@faker-js/faker';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom' ;

const DoctorAppointmentPage = () => {
  const [appointments, setAppointments] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('Upcoming');
  const currentDate = new Date();
  const { userData } = useSelector((state) => state.auth);
  const UserId = userData?.user?.id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await apiInstance.get(`/api/Doctor/GetDoctorAppointmentDetails?UserId=${UserId}`);
        console.log(response.data);
        setAppointments(response.data);
      } catch (error) {
        console.error('Error fetching appointments:', error.message);
      }
    };

    fetchAppointments();
  }, [UserId]);

  const filterAppointments = () => {
    switch (selectedFilter) {
      case 'Upcoming':
        return appointments.filter(appointment => new Date(appointment.requestedDate) >= currentDate && appointment.status === "Booked");
      case 'Historic':
        return appointments.filter(appointment =>  appointment.status === "Completed");
      case 'Cancelled':
        return appointments.filter(appointment => appointment.status === "Cancelled");
      default:
        return [];
    }
  };

  const renderAppointments = () => {
    const filteredAppointments = filterAppointments();

    return filteredAppointments.map((appointment) => (
      <div className="appointment-card" key={appointment.consultationId}>
        <img className="appointment-image" src={faker.image.avatar()}  />
        <div className="appointment-details">
          <h3>{appointment.patientName}</h3>
          <p>ConsultationType: {appointment.consultationType}</p>
          <p>Date: {format(new Date(appointment.requestedDate), 'MM/dd/yyyy')}</p> 
          <p>Time: {appointment.selectedTimeSlot}</p>
          
          {appointment.consultationType === 'Video' && (
        <Link to={`/Video/${appointment.consultationId}`}>
          Join Consultation
        </Link>
      )}


          
          <button
          className="details-button"
          onClick={() => handleDetailsButtonClick(appointment.consultationId)}
        >Details</button>
        </div>
      </div>
    ));
  };
  const handleDetailsButtonClick = (consultationId) => {
    // Navigate to AppointmentDetailsPage and pass consultationId as a parameter
    navigate(`/appointment-details/${consultationId}`);
  };

  return (
    <Layout>
    <div>
      <div className="filter-dropdown">
        <label>Filter by:</label>
        <select onChange={(e) => setSelectedFilter(e.target.value)}>
          <option value="Upcoming">Upcoming Appointments</option>
          <option value="Historic">Historic Appointments</option>
          <option value="Cancelled">Cancelled Appointments</option>
        </select>
      </div>
      <div className="space-after-dropdown"></div>

      <div className="appointment-container">
        {appointments.length === 0 ? (
          <p>No appointments found.</p>
        ) : (
          renderAppointments()
        )}
      </div>
    </div>
    </Layout>
  );
};

export default DoctorAppointmentPage;

