import axios from 'axios';
  const baseURL= 'https://usermanagementapi20240819201611.azurewebsites.net/';
// const baseURL= 'https://localhost:5001/';

const apiInstance = axios.create({
    baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set the token in the headers for every request
apiInstance.interceptors.request.use(
  (config) => {
    const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const token = userData?.token; 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiInstance;
