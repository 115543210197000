import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import '../styles/LoginStyles.css';
import FormContainer from '../components/FormContainer';
import apiInstance from './AxiosConfig'; 
import { toast } from 'react-toastify';
export default function ForgotPassword() {
  const [username, setUsername] = useState('');
 
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      
      const response = await apiInstance.post('/api/Authentication/ForgotPassword', {
        username,
        
      });

      
      toast.success('Password reset request successful,Check your email', response.data);
      navigate('/login');



    } catch (error) {
      // Handle errors (e.g., display an error message)
      toast.error('Password reset request failed,You are not a member', error.response.data);
    }
  };

  return (
    <div className="bckground-image">
      <FormContainer>
        <h1 className="form-header">Reset Password Details</h1>
        <Form onSubmit={submitHandler} className="form-container">
          <Form.Group controlId="username">
            <Form.Label>UserName</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          

          
          <button type="submit">Submit</button>
        </Form>
      </FormContainer>
    </div>
  );
}
