import { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDoctorregisterMutation } from '../redux/features/usersApiSlice';
import { setCredentials } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Layout from "./../components/Layout";



const DoctorRegisterScreen = () => {
  const [username, setUsername] = useState('');
 
  const [firstname, setFirstname] = useState('');
  
  const [lastname, setLastname] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  
  
  const[phonenumber,setPhonenumber]=useState('');
  
  const[streetaddress,setStreetaddress]=useState('');
  

  const[city,setCity]=useState('');
  

  const[country,setCountry]=useState('');
 

  const[postalcode,setPostalcode]=useState('');
  
  const[dateofbirth,setDateofbirth]=useState('');
  

  const[experience,setExperience]=useState('');
  
  const[personalnumber,setPersonalnumber]=useState('');

  const[qualification,setQualification]=useState('');
  const[specialization,setSpecialization]=useState('');
  
  

  const dispatch = useDispatch();
const navigate = useNavigate();

const [ Doctorregister, { isLoading }] = useDoctorregisterMutation();

const { userData } = useSelector((state) => state.auth);
 const adminId=userData?.user?.id;
 
 console.log("adminId",adminId);



  const submitHandler = async (e) => {
  e.preventDefault();
  


  
  if(!username || !firstname || !lastname || !email ||  !phonenumber || !streetaddress || !city || !country || !postalcode || !dateofbirth || !experience ||  !personalnumber|| !qualification ||!specialization || !gender){
    toast.error('All fields are required');
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    toast.error('Invalid email format');
  }
  
  
    try {
      const res = await  Doctorregister({adminId, phonenumber,username, firstname,lastname,gender,email,streetaddress,city,country,postalcode,dateofbirth,experience,personalnumber,qualification,specialization }).unwrap();
      console.log("res",res);
      //dispatch(setCredentials({ ...res }));
      toast.success('Registration successful!');
      navigate("/");
      console.log("registatin");
      

      
      
    } catch (err) {
      toast.error(err?.data?.message || err.error);
      console.error("regerror",err);
    }
  
};

  return (
    <Layout>
    <FormContainer>
      <h1>Add Doctor</h1>
      <Form onSubmit={submitHandler}>



      

       
      <Form.Group className='my-2' controlId='username'>
          <Form.Label>UserName</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>


        
        <Form.Group className='my-2' controlId='firstname'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Firstname'
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>


        <Form.Group className='my-2' controlId='lastname'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter Lastname'
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='gender'>
        <Form.Label>Gender</Form.Label>
        <Form.Control
          as='select'
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </Form.Control>
      </Form.Group>


        <Form.Group className='my-2' controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='phonenumber'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='tel'  pattern="[0-9]{10}"
            
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          ></Form.Control>
        </Form.Group>
         

        <Form.Group className='my-2' controlId='streetaddress'>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Street Address'
            value={streetaddress}
            onChange={(e) => setStreetaddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>

        </Form.Group>

        <Form.Group className='my-2' controlId='postalcode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Postal Code'
            value={postalcode}
            onChange={(e) => setPostalcode(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='dateofbirth'>
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            type='date'
            placeholder='Enter Date Of Birth'
            value={dateofbirth}
            onChange={(e) => setDateofbirth(e.target.value)}
          ></Form.Control>
        </Form.Group>
        

        

        <Form.Group className='my-2' controlId='personalnumber'>
          <Form.Label>Personal Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Personal number'
            value={personalnumber}
            onChange={(e) => setPersonalnumber(e.target.value)}
          ></Form.Control>
        </Form.Group>


        

        <Form.Group className='my-2' controlId='qualification'>
          <Form.Label>Qualification</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter your qualification'
            value={qualification}
            onChange={(e) => setQualification(e.target.value)}
           
          ></Form.Control>
          
        </Form.Group>

        <Form.Group className='my-2' controlId='experience'>
          <Form.Label>Experience</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter number of experience'
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          ></Form.Control>
        </Form.Group>


        <Form.Group className='my-2' controlId='specialization'>
          <Form.Label>Specialization</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter your specialization'
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          ></Form.Control>
        </Form.Group>
       

        
        
        {
     isLoading && <Loader />
        }
        <Button type='submit' variant='primary' className='mt-3'  >
          Add
        </Button>
      </Form>

      
    </FormContainer>
    </Layout>
  );
};

export default DoctorRegisterScreen;