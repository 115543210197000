import { useSelector } from "react-redux";
import {Route,Routes}from "react-router-dom";
import DoctorHomePage from "../pages/DoctorHomePage";
import DoctorProfileScreen from "../pages/DoctorProfile";
import DoctorNotificationPage from "../pages/DoctorNotificationPage";
import ResetPassword from "../pages/ResetPassword";
import DoctorAvailabilityPage from "../pages/DoctorAvailability";
import AppointmentDetails from "../pages/AppointmentDetails";
import DoctorAppointmentPage from "../pages/DoctorAppointmentPage";
import PrescriptionPage from "../pages/PrescriptionPage";
import PatientProfileViewByDoctor from "../pages/PatientProfileViewByDoctor";
import PrescriptionDetails from "../pages/PrescriptionDetails";
import RoomPage from "../pages/ChatRoom";
import Video from "../pages/Video";
import DoctorAvailabilityinWeek from "../pages/DoctorAvailabilityinWeek";
const DoctorRoute=()=>{
    return (
        <Routes>
          <Route path="/" element={<DoctorHomePage />} />

          <Route path="/DoctorProfile" element={<DoctorProfileScreen/>} />
          <Route path="/notifications" element={<DoctorNotificationPage/>} />
          <Route path="/ResetPassword" element={<ResetPassword/>} />
          <Route path="/doctorAvailability" element={<DoctorAvailabilityPage/>} />
          <Route path="/doctoravailabilityinweek" element={<DoctorAvailabilityinWeek/>} />
          <Route path="/doctorAppointments" element={<DoctorAppointmentPage/>} />
          <Route path="/appointment-details/:consultationId" element={<AppointmentDetails/>} />
            
          <Route path="/prescription/:consultationId" element={<PrescriptionPage/>} />
          <Route path="/PatientProfileViewByDoctor/:consultationId" element={<PatientProfileViewByDoctor/>} />

          <Route path="/PrescriptionDetails/:consultationId" element={<PrescriptionDetails/>} />
          <Route path="/room/:roomId" element={<RoomPage/>} />
          <Route path="/Video/:consultationId" element={<Video/>} />
        </Routes>
      );
};
export default DoctorRoute;