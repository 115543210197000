import React, { useState, useEffect } from "react";
import { navigate } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom';
import paymentimg from '../images/paymentimg.jpg';
import Layout from "../components/Layout";
import { useLocation } from 'react-router-dom';
import '../styles/Payment.css';
import apiInstance from './AxiosConfig';

const Payment = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { doctorName,
    patientName,
    consultationId,
    consultationFees,
    requestedDate,
 selectedTimeSlot,
 
 consultationType} = location.state || {};
 
  const handleCheckout = (event) => {
    event.preventDefault(); // Prevent the default form submission

    // Check if payment has already been made for this consultation
    const isPaymentMade = JSON.parse(localStorage.getItem('paymentpending'));
    console.log("ispaymentMade", isPaymentMade);

    if (isPaymentMade === false) {
      setMessage('Payment has already been made for this consultation.');
    } else {
      // Add any additional logic here before submitting the form
      // For example, updating local storage
     

      // Proceed with form submission
      const form = event.target.closest('form');
      if (form) {
        form.submit(); // Submit the form programmatically
      }
    }
  };



  useEffect(() => {
    // Handle payment success or cancellation
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      // Payment successful, update local storage
     
      navigate("/success");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const ProductDisplay = () => (
    <section>
      <Layout>
        <div className="payment-details">
          <img src={paymentimg} alt="The cover of Stubborn Attachments" className="payment-image" />
          <div className="description">
            <h3>Payment Details</h3>
            <p>Doctor Name: {doctorName}</p>
            <p>Patient Name: {patientName}</p>
            <p>Requested Date: {requestedDate ? new Date(requestedDate).toLocaleDateString() : 'N/A'}</p>

            <p>Selected Time Slot: {selectedTimeSlot}</p>
            <p>Consultation Type: {consultationType}</p>
            <p>Payment Amount: {consultationFees}</p>
            <form action={`${apiInstance.defaults.baseURL}/create-checkout-session?consultationId=${consultationId}`} method="POST">

              {/* Include hidden input fields for additional details */}
              <input type="hidden" id="doctorName" name="doctorName" value={doctorName} />
              <input type="hidden" id="patientName" name="patientName" value={patientName} />
              <input type="hidden" id="requestedDate" name="requestedDate" value={requestedDate} />
              <input type="hidden" id="selectedTimeSlot" name="selectedTimeSlot" value={selectedTimeSlot} />
              <input type="hidden" id="consultationType" name="consultationType" value={consultationType} />
              <input type="hidden" id="paymentAmount" name="paymentAmount" value={consultationFees} />
              <button type="submit" onClick={handleCheckout} className="checkout-button">Checkout</button>
            </form>
          </div>
        </div>
      </Layout>
    </section>
  );
  
  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay />
  );
};

export default Payment;
