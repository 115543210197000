import React from 'react';
import { useParams } from 'react-router-dom';
import{ZegoUIKitPrebuilt} from "@zegocloud/zego-uikit-prebuilt";
import { useSelector } from 'react-redux';

const RoomPage = () => {

    const{roomId}=useParams();
    const { userData } = useSelector((state) => state.auth);
    const username = userData?.user?.firstName;
    const myMeeting=  async (element) => {
        const appID = 1940835019;
        const serverSecret = "6abec71cec94208fa285a01a24b1a522";
        const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(
            appID, 
            serverSecret, 
            roomId,
              Date.now().toString(),
              username
              );
  
     const zc=  ZegoUIKitPrebuilt.create(kitToken);
     zc.joinRoom(
        {

            container:element ,
            

            sharedLinks:[
                {
                name:'Copy Link',
                url:`https://doctorpatient.no/room/${roomId}`

            }
        
        ],


            
            scenario:{
                mode:ZegoUIKitPrebuilt.OneONoneCall,
            },
            showScreenSharingButton:true,

        }
     );
    };
  return (
   <div>
     <div  ref={myMeeting} />


   </div>
  )
}

export default RoomPage
