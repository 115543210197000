import { useSelector } from "react-redux";
import {Route,Routes}from "react-router-dom";

import PatientHomePage from "../pages/PatientHomePage";
import AssignChangeDoctorPage from "../pages/AssignOrChangeDoctor";
import PatientProfileScreen from "../pages/PatientProfile";
import AssignedDoctorDetails from "../pages/AssignedDoctorDetails";
import ResetPassword from "../pages/ResetPassword";
import DoctorNotificationPage from "../pages/DoctorNotificationPage";
import ConsultationBookingPage from "../pages/ConsultationBookingPage";

import PatientPrescriptions from "../pages/PatientPrescriptions";
import CardDetails from "../pages/CardDetails";
import Payment from "../pages/Payment";
import StripeTest from "../pages/StripeTest";
import Success from "../pages/Success";
import RoomPage from "../pages/ChatRoom";
import Video from "../pages/Video";
import ConsultationsPage from "../pages/Consulattions";

const PatientRoute=()=>{
    return (
        <Routes>
          <Route path="/" element={<PatientHomePage />} />
          <Route path="/assignOrChangeDoctor" element={<AssignChangeDoctorPage />} />
          <Route path="/patientProfile" element={<PatientProfileScreen />} />
          <Route path="/assignedDoctorDetails" element={<AssignedDoctorDetails/>} />
          <Route path="/ResetPassword" element={<ResetPassword/>} />
          <Route path="/notifications" element={<DoctorNotificationPage/>} />
          <Route path="/consultationBooking" element={<ConsultationBookingPage/>} />

          <Route path="/Consultations" element={<ConsultationsPage/>} />
          <Route path="/PatientPrescriptions" element={<PatientPrescriptions/>} />
          <Route path="/enterCardDetails" element={<CardDetails/>} />
          <Route path="/payment" element={<Payment/>} />
          <Route path="/stripeTest" element={<StripeTest/>} />
          <Route path="/success" element={<Success/>} />
          <Route path="/room/:roomId" element={<RoomPage/>} />

          <Route path="/Video/:consultationId" element={<Video/>} />
         
          
          
          
          
        </Routes>
      );
};
export default PatientRoute;