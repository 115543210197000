import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import apiInstance from './AxiosConfig';

const Video = () => {
  const { consultationId } = useParams(); 
  const [consultationLink, setConsultationLink] = useState(null);
  const [message, setMessage] = useState('Please join the meeting on the allotted date and time'); // Message state

  useEffect(() => {
    const fetchConsultationLink = async () => {
      try {
        const response = await apiInstance.get(`/api/Doctor/GetConsulationLink?ConsultationId=${consultationId}`);
        if (response.data.roomLink) {
          // If roomLink is available, set it
          setConsultationLink(response.data.roomLink);
        } else {
          // If roomLink is not available, set the message
          setMessage('Please join the meeting on the allotted date and time');
        }
      } catch (error) {
        console.error('Error fetching consultation link:', error);
      }
    };

    fetchConsultationLink();
  }, [consultationId]);

  return (
    <Layout>
      <div>
        {/* Render the link or message */}
        {consultationLink ? (
          <a href={consultationLink} target="_blank" rel="noopener noreferrer">Join Consultation</a>
        ) : (
          <p style={styles.message}>{message}</p>
        )}
      </div>
    </Layout>
  );
};

const styles = {
  message: {
    fontSize: '20px',
    color: 'red',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  }
};

export default Video;