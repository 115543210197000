import React, { useEffect, useState } from 'react';
import { useParams,useNavigate ,Link} from 'react-router-dom';
import apiInstance from './AxiosConfig';
import Layout from '../components/Layout';
import { faker } from '@faker-js/faker';
import '../styles/AppointmentDetailsPage.css';

const PatientProfileViewByDoctor = () => {
  const { consultationId } = useParams();
  const [patientDetails, setPatientDetails] = useState({});
  const [completedConsultations, setCompletedConsultations] = useState([]);
 
  const navigate=useNavigate();


  useEffect(() => {
    const fetchPatientDetails = async () => {
      try {
        const response = await apiInstance.get(`/api/Doctor/GetPatientDetails?consultationId=${consultationId}`);
        console.log(response.data);

        if (Array.isArray(response.data) && response.data.length > 0) {
          setPatientDetails(response.data[0]);
        }

        
      } catch (error) {
        console.error('Error fetching patient details:', error.message);
      }
    };

    fetchPatientDetails();
  }, [consultationId]);

  useEffect(() => {
    const fetchCompletedConsultations = async () => {
      try {
        const response = await apiInstance.get(`/api/Doctor/GetCompletedConsultationDetails?ConsultationId=${consultationId}`);
        
        setCompletedConsultations(response.data);
      } catch (error) {
        console.error('Error fetching completed consultations:', error.message);
      }
    };

    fetchCompletedConsultations();
  }, [consultationId]);
  const handlePrescriptionClick = () => {
    // Navigate to PrescriptionPage with the consultationId
    navigate(`/PrescriptionDetails/${consultationId}`);
  };
  
  const dobWithoutTime = patientDetails.dateOfBirth ? patientDetails.dateOfBirth.split('T')[0] : '';
  return (
    <Layout>
      <div className="appointment-details-container">
        <h2>Profile Details</h2>

        <div className="patient-info">
          <img className="patient-image" src={faker.image.avatar()} alt="Patient" />
          <div className="patient-details">
            <p><strong>Name:</strong> {`${patientDetails.firstName} ${patientDetails.lastName}`}</p>
            <p><strong>Gender:</strong> {patientDetails.gender}</p>
            <p><strong>DOB:</strong> {dobWithoutTime}</p>
            <p><strong>Height:</strong> {patientDetails.height} cm</p>
            <p><strong>Weight:</strong> {patientDetails.weight} kg</p>
          </div>
        </div>

        

        <div className="action-buttons">
          <button className="action-button"  onClick={handlePrescriptionClick}>Prescriptions</button>
          <Link to={`/appointment-details/${consultationId}`} className="action-link"> Back to Appointment Details</Link>
          
        </div>
      </div>


      <div className="completed-consultations">
        <h2>Completed Consultations</h2>
        <table>
          <thead>
            <tr>
              <th>Hospital Name</th>
              <th>Doctor Name</th>
              <th>Consultation Type</th>
              <th> Date</th>
              <th>Time Slot</th>
            </tr>
          </thead>
          <tbody>
            {completedConsultations.map((consultation, index) => (
              <tr key={index}>
                <td>{consultation.hospitalName}</td>
                <td>{consultation.doctorName}</td>
                <td>{consultation.consultationType}</td>
                <td>{new Date(consultation.requestedDate).toISOString().split('T')[0]}</td>
                <td>{consultation.selectedTimeSlot}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default PatientProfileViewByDoctor;
