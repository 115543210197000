import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Col, Row, Dropdown } from 'react-bootstrap';
import Layout from '../components/Layout';
import axios from 'axios';
import { faker } from '@faker-js/faker';
import { Card } from 'react-bootstrap';
import apiInstance from './AxiosConfig';
import { toast } from 'react-toastify';


const AssignChangeDoctorPage = () => {
  const [searchOption, setSearchOption] = useState(''); 
  const [selectedCity, setSelectedCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [hospitalName, setHospitalName] = useState('');
  const [cities, setCities] = useState([]); 
  const [doctors, setDoctors] = useState([]); 
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  useEffect(() => {
    // Reset selectedDoctorId when doctors array changes
    setSelectedDoctorId(null);
  }, [doctors]);


  useEffect(() => {
    
    const userDataString = localStorage.getItem('userData');
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const token = userData?.token;
    // await apiInstance.delete(`/api/DoctorList?doctorId=${doctorId}`);
    if (searchOption === 'city') {
      apiInstance
        .get(`/api/DoctorList/Cities` )
        .then((response) => {
          setCities(response.data);
        })
        .catch((error) => {
          console.error('Error fetching city data:', error);
        });
    }
  }, [searchOption]); 
  
  const handleSearchOptionChange = (option) => {
    setSearchOption(option);
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
  };
  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const UserId=userData?.user?.id;
  const token = userData?.token;
  const handleSelect = (selectedDoctor) => {
    console.log("UserId", UserId);

    
    const doctorId = selectedDoctor.doctorId;
    const status="Pending";

   
    setSelectedDoctorId(doctorId);

    
    const requestData = {
      doctorId: doctorId,
      UserId: UserId,
      status:status,
      
    };

    
    

    
    apiInstance.post(`/api/DoctorList/AssignDoctorrequest`, requestData)
      .then((response) => {

        console.log('Request sent successfully:', response.data);
        if(response.data.status === 'NotExists'){
          toast.success('Your request has been processed. Please wait for approval.');
        }

        else if(response.data.status === "Pending"){
          toast.success('Your request for this doctor is still pending.Please wait');
        }
        else if(response.data.status === 'approved'){
          toast.success(' You have already been assigned this doctor. ');
        }
        else if(response.data.status ==="CannotRequest"){

          toast.success('You cant request more than one doctor at a time.Already a pending request you have');
        }
        
      })
      .catch((error) => {
        // Handle error
        console.error('Error sending request:', error);
      });
  };
  const handleSearch = () => {
    console.log('Searching...');
  
    // Create an object to hold query parameters
    const queryParams = {};
  
    if (searchOption === 'city') {
      queryParams.city = selectedCity;
    } else if (searchOption === 'pincode') {
      queryParams.pincode = pincode;
    } else if (searchOption === 'hospitalname') {
      queryParams.hospitalName = hospitalName;
    }
  
    // Use apiInstance to make the request, including the query parameters
    apiInstance.get("/api/DoctorList/Search", { params: queryParams })
      .then((response) => {
        console.log('Search results:', response.data);
        setDoctors(response.data);
      })
      .catch((error) => {
        console.error('Error during search:', error);
      });
  };
  

  

  return (
    <Layout>
      <Container className="text-center">
        <h1 style={{ marginBottom: '50px' }}>
          Assign/Change Doctor{' '}
          <span role="img" aria-label="question mark">
            ❓
          </span>
        </h1>
        <h3 style={{ marginBottom: '100px' }}>Find Doctors</h3>

        <Form>
          <Row className="mb-3">
            <Col md={3}>
              <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Search Option:</Form.Label>
            </Col>
            <Col md={9}>
            <Row>
                <Col md={2}>
              <Form.Check
                type="radio"
                label="By City"
                name="searchOption"
                checked={searchOption === 'city'}
                onChange={() => handleSearchOptionChange('city')}
              />
               </Col>
                <Col md={2}>
              <Form.Check
                type="radio"
                label="By Pincode"
                name="searchOption"
                checked={searchOption === 'pincode'}
                onChange={() => handleSearchOptionChange('pincode')}
              />
               </Col>
                <Col md={2}>
              <Form.Check
                type="radio"
                label="By Hospital Name"
                name="searchOption"
                checked={searchOption === 'hospitalname'}
                onChange={() => handleSearchOptionChange('hospitalname')}
              />
            </Col>
          </Row>
          </Col>
          </Row>

          {searchOption === 'city' && (
            <Row className="mb-3">
              <Col md={3}>
                <Form.Label>Select City:</Form.Label>
              </Col>
              <Col md={2}>
                <Dropdown onSelect={(selectedCity) => handleCityChange(selectedCity)}>
                  <Dropdown.Toggle variant="light" id="cityDropdown">
                    {selectedCity || 'Select City'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {cities.map((city, index) => (
                      <Dropdown.Item key={index} eventKey={city}>
                        {city}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          )}

          {searchOption === 'pincode' && (
            <Row className="mb-3">
              <Col md={3}>
                <Form.Label>Enter Pincode:</Form.Label>
              </Col>
              <Col md={9}>
                <Form.Control
                  type="text"
                  placeholder="Enter pincode"
                  onChange={(e) => setPincode(e.target.value)}
                />
              </Col>
            </Row>
          )}

          {searchOption === 'hospitalname' && (
            <Form.Group controlId="searchText" className="mb-3">
              <Form.Label>Enter Hospital Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Hospitalname"
                onChange={(e) => setHospitalName(e.target.value)}
              />
            </Form.Group>
          )}
         
         <Row className="mt-3">
         <Col md={12} className="text-center">
              <Button
                variant="primary"
                type="button"
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Col>
          </Row>

          <Row className="mt-4">
  {doctors.map((doctor, index) => (
    <Col key={index} md={4} className="mb-3">
      <Card>
        <Card.Body>
          
          <img
            src={faker.image.avatar()}
            alt="Doctor Avatar"
            style={{ width: '50px', height: '50px' }} 
          />
          <h5 className="mt-3">{doctor.firstName}</h5>
          <p>Hospital Name: {doctor.hospitalName}</p>
          <p>Phone Number: {doctor.phoneNumber}</p>
          <p>Qualification: {doctor.qualification}</p>
          <p>Experience: {doctor.experience} years</p>
          <p>Specialization: {doctor.specialization}</p>
          <Button variant="primary" onClick={() => handleSelect(doctor)}>
            Select
          </Button>
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>

        </Form>
      </Container>
    </Layout>
  );
};

export default AssignChangeDoctorPage;
