import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import '../styles/DoctorAvailabilityPage.css';
import Layout from '../components/Layout';
import apiInstance from './AxiosConfig';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const DoctorAvailabilityPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [availabilitySlots, setAvailabilitySlots] = useState([]);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [selectWeek, setSelectWeek] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const UserId = userData?.user?.id;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSelectedDates = async () => {
      try {
        const apiUrl = `/api/Doctor/GetAvailableDates?UserId=${UserId}`;
        const response = await apiInstance.get(apiUrl);
  
        if (response.data && response.data.length > 0) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
  
            // Filter dates greater than or equal to the current date
            const filteredDates = response.data
              .map((date) => new Date(date.workingDate))
              .filter((date) => date >= currentDate);
  
            setHighlightedDates(filteredDates);
          }
        } catch (error) {
          console.error('Error fetching selected dates:', error.message);
        }
      };
  
      fetchSelectedDates();
    }, [UserId]);

  const handleDateChange = async (date) => {
    console.log(date);
    setSelectedDate(date);
  
    try {
      const apiUrl = `/api/Doctor/GetAvailableDates?UserId=${UserId}`;
      const response = await apiInstance.get(apiUrl);
  
      if (response.data && response.data.length > 0) {
        // Filter slots for the selected date
        const selectedDateSlots = response.data.filter(
          (slot) => new Date(slot.workingDate).toDateString() === date.toDateString()
        );
        setAvailabilitySlots(selectedDateSlots);
      }
    } catch (error) {
      console.error('Error fetching selected slots:', error.message);
    }
  };
  
  const addAvailabilitySlot = () => {
    if (fromTime && toTime && new Date(`2000-01-01 ${toTime}`) > new Date(`2000-01-01 ${fromTime}`)) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const selectedDateWithoutTime = new Date(selectedDate);
      selectedDateWithoutTime.setHours(0, 0, 0, 0);

      if (selectedDateWithoutTime >= currentDate) {
        const newSlot = { workingDate: selectedDate, from: fromTime, to: toTime };
        const slotExists = availabilitySlots.some(slot => slot.from === fromTime && slot.to === toTime);
        if (!slotExists) {
        setAvailabilitySlots([...availabilitySlots, newSlot]);
        setFromTime('');
        setToTime('');
        }
        else {
          toast.error('This time slot already exists. Please enter a different slot.');
        }
      } else {
        toast.error('Selected date cannot be in the past.');
      }
    } else {
      toast.error('Invalid time range. "To" time must be greater than "From" time.');
    }
  };

  const removeAvailabilitySlot = (indexToRemove) => {
    const updatedSlots = [...availabilitySlots];
    updatedSlots.splice(indexToRemove, 1);
    setAvailabilitySlots(updatedSlots);
  };

  const saveAvailability = async () => {
    if (availabilitySlots.length === 0) {
      toast.error('Please add at least one availability slot.');
      return;
    }

    try {
      console.log("*************selectedDate");console.log(selectedDate)
      const apiUrl = '/api/Doctor/SaveDoctorAvailability';
      const requestData = {
        userId: UserId,
        WorkingDates: [selectedDate],
        availabilitySlots,
      };

      const response = await apiInstance.post(apiUrl, requestData);
      console.log(response.data.message);
      console.log(response.data);
      if (response && response.status === 200) {
      if (response.data.message === 'Success') {
        setHighlightedDates([...highlightedDates, selectedDate]);
        setAvailabilitySlots([]);
        toast.success('Availability slots saved successfully');
      } else {
        toast.error('Failed to save availability:');
      }}
    } catch (error) {
      console.error('Error saving availability:', error.message);
    }
  };
  const handleCheckboxChange = () => {
    setSelectWeek(!selectWeek); // Toggle the checkbox state
    if (!selectWeek) {
      navigate('/doctoravailabilityinweek'); // Navigate to "/doctoravailabilityinweek"
    }
  };

  return (
    <Layout>
      <div className="doctor-availability-container">
      <div className="top-bar">
      <div className="checkbox-container">
          <label>
            Select Multiple Dates:
            <input
              type="checkbox"
              checked={selectWeek}
              onChange={handleCheckboxChange}
            />
          </label>
          </div>
        </div>
        <div className="calendar-and-checkbox">
        <div className="calendar-container">
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileClassName={({ date }) =>
              highlightedDates.some((highlightedDate) => highlightedDate.toDateString() === date.toDateString())
                ? 'selected-date'
                : ''
            }
          />
        </div>
        </div>
        <div className="availability-details-container">
          <h3 className={selectedDate.toDateString() === new Date().toDateString() ? 'today' : ''}>
            Selected Date: {selectedDate.toDateString()}
          </h3>
          {selectedDetails && (
            <div>
              <p>From: {selectedDetails.from}</p>
              <p>To: {selectedDetails.to}</p>
            </div>
          )}
          <div>
            <label>From:</label>
            <input type="time" value={fromTime} onChange={(e) => setFromTime(e.target.value)} />
          </div>
          <div>
            <label>To:</label>
            <input type="time" value={toTime} onChange={(e) => setToTime(e.target.value)} />
          </div>
          <button onClick={addAvailabilitySlot}>+</button>
          <div className="availability-slots">
            {availabilitySlots.map((slot, index) => (
              <div key={index} className="selected">
                {slot.from} - {slot.to}
                <button onClick={() => removeAvailabilitySlot(index)}>Remove</button>
              </div>
            ))}
          </div>
          <button onClick={saveAvailability}>Save Availability</button>
        </div>
      </div>
    </Layout>
  );
};

export default DoctorAvailabilityPage;