import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import apiInstance from './AxiosConfig';
import { useSelector } from 'react-redux';

function Success() {
  const { userData } = useSelector((state) => state.auth);
  const userId = userData?.user?.id;
  const [consultationDetails, setConsultationDetails] = useState(null);
  const [actionsCompleted, setActionsCompleted] = useState({
    payment: false,
    statusChange: false
  });

  useEffect(() => {
    // Make API request to fetch consultation details
    const fetchData = async () => {
      try {
        const response = await apiInstance.get(`/api/Patient/GetConsultationDetails?UserId=${userId}`);
        setConsultationDetails(response.data[0]);
      } catch (error) {
        console.error('Error fetching consultation details:', error);
      }
    };

    fetchData();
  }, [userId]);

  // Function to save payment details
  const savePaymentDetails = async () => {
    try {
      if (consultationDetails) {
        // Make API request to save payment details
        await apiInstance.post('/api/Patient/SavePaymentDetails', {
          ConsultationId: consultationDetails?.id, 
        });
        
        localStorage.setItem('paymentpending', JSON.stringify(false));
        // Update state to indicate payment details are saved
        setActionsCompleted(prevState => ({
          ...prevState,
          payment: true
        }));
      }
    } catch (error) {
      console.error('Error saving payment details:', error);
    }
  };

  const changeStatusInConsultation = async () => {
    try {
      if (consultationDetails) {
        await apiInstance.post('/api/Patient/ChangeConsultationStatus', {
          ConsultationId: consultationDetails?.id, 
        });
        setActionsCompleted(prevState => ({
          ...prevState,
          statusChange: true
        }));
      }
    } catch (error) {
      console.error('Error changing status in consultation:', error);
    }
  };

  // Extract date part from the date-time string
  const formattedDate = consultationDetails?.requestedDate ? new Date(consultationDetails.requestedDate).toLocaleDateString() : '';

  return (
    <Layout>
      <div>
        <h1>Successfully Booked Consultation</h1>
        {consultationDetails ? (
          <div>
            <table className="consultation-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Doctor Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{formattedDate}</td>
                  <td>{consultationDetails.selectedTimeSlot}</td>
                  <td>{consultationDetails.doctorName}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <p>Loading...</p>
        )}

        {/* Message after payment details are saved */}
        {actionsCompleted.payment && (
          <p>Payment details saved successfully.</p>
        )}

        {/* Button to save payment details */}
        {!actionsCompleted.payment && (
          <button onClick={savePaymentDetails}>Save Payment Details</button>
        )}

        {/* Button to change status in consultation */}
        {actionsCompleted.payment && !actionsCompleted.statusChange && (
          <button onClick={changeStatusInConsultation}>Change Consultation Status</button>
        )}
      </div>
      <br></br>
      <p>You successfully booked the consultation. Check your email for details.</p>
      <style jsx>{`
        .consultation-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px; /* Adjust margin as needed */
        }

        .consultation-table th,
        .consultation-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .consultation-table th {
          background-color: #f2f2f2;
        }

        .consultation-table tbody tr:nth-child(even) {
          background-color: #f2f2f2;
        }
      `}</style>
    </Layout>
  );
}

export default Success;
