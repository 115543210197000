import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./../components/Layout";
import apiInstance from './AxiosConfig';

const PatientHomePage = () => {
  const [notificationDetails, setNotificationDetails] = useState([]);
  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const UserId = userData?.user?.id;
  const roleName = userData?.user?.roleName;
  const navigate = useNavigate();
  const checkRedirectToSuccess = () => {
    const params = new URLSearchParams(window.location.search);
    const successParam = params.get("success");
    if (successParam === "true") {
      navigate("/success");
    }
  };
  const checkRedirectToVideo = () => {
    const params = new URLSearchParams(window.location.search);
    const roomParam = params.get("room");
    if (roomParam ) {
      navigate(`/room/${roomParam}`);
      

      
    }
  };

  useEffect(() => {
    checkRedirectToSuccess();
    checkRedirectToVideo();
    if (UserId) {
      // Fetch notification details
      apiInstance.get(`/api/Notification/GetNotification?UserId=${UserId}`)
        .then(response => {
          // Set notification details in state
          setNotificationDetails(response.data);
        })
        .catch(error => {
          console.error("Error fetching notifications:", error);
        });
    }
  }, [UserId]);

  return (
    <Layout roleName={roleName} notificationDetails={notificationDetails}>
      <h1>Patient Home Page</h1>
      {/* You can render notification details here if needed */}
    </Layout>
  );
};

export default PatientHomePage;
