import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiInstance from './AxiosConfig';
import Layout from '../components/Layout';
import { faker } from '@faker-js/faker';
import '../styles/AppointmentDetailsPage.css';
import { toast } from 'react-toastify';

const AppointmentDetails = () => {
  const { consultationId } = useParams();
  const [patientDetails, setPatientDetails] = useState({});
  const [patientComments, setPatientComments] = useState([]);
  const [completedButtonClicked, setCompletedButtonClicked] = useState(false); // State to track button click
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatientDetails = async () => {
      try {
        const response = await apiInstance.get(`/api/Doctor/GetPatientDetails?consultationId=${consultationId}`);

        if (Array.isArray(response.data) && response.data.length > 0) {
          setPatientDetails(response.data[0]);
        }

        const commentsResponse = await apiInstance.get(`/api/Doctor/GetPatientComments?ConsultationId=${consultationId}`);

        if (Array.isArray(commentsResponse.data) && commentsResponse.data.length > 0) {
          setPatientComments(commentsResponse.data);
        }
      } catch (error) {
        console.error('Error fetching patient details:', error.message);
      }
    };

    fetchPatientDetails();
  }, [consultationId]);

  const handleAddPrescriptionClick = () => {
    navigate(`/prescription/${consultationId}`);
  };

  const handleViewProfileClick = () => {
    navigate(`/PatientProfileViewByDoctor/${consultationId}`);
  };

  const handleCompletedButtonClick = async () => {
    if (!completedButtonClicked) {
      try {
        const response = await apiInstance.post('/api/Doctor/changeStatusInConsultation', { consultationId });

        if (response && response.status === 200) {
          if (response.data && response.data.message === 'Success') {
            toast.success('Consultation finished successfully');
          } else {
            toast.error('Failed : ' + (response.data && response.data.message ? response.data.message : 'Unknown error'));
          }
        } else {
          toast.error('Failed To Book: Unexpected response structure');
        }
      } catch (error) {
        console.error('Error saving availability:', error.message);
      } finally {
        setCompletedButtonClicked(true);
      }
    }
  };

  const dobWithoutTime = patientDetails.dateOfBirth ? patientDetails.dateOfBirth.split('T')[0] : '';

  return (
    <Layout>
      <div className="appointment-details-container">
        <h2>Patient Details</h2>

        <div className="patient-info">
          <img className="patient-image" src={faker.image.avatar()} alt="Patient" />
          <div className="patient-details">
            <p><strong>Name:</strong> {`${patientDetails.firstName} ${patientDetails.lastName}`}</p>
            <p><strong>Gender:</strong> {patientDetails.gender}</p>
            <p><strong>DOB:</strong> {dobWithoutTime}</p>
            <p><strong>Height:</strong> {patientDetails.height} cm</p>
            <p><strong>Weight:</strong> {patientDetails.weight} kg</p>
          </div>
        </div>

        <div className="patient-comments">
          <strong>Comments:</strong>
          <p>{patientComments[0]?.comments}</p>
        </div>

        <div className="action-buttons">
          <button className="action-button" onClick={handleAddPrescriptionClick}>Add Prescription</button>
          <button className="action-button" onClick={handleViewProfileClick}>View Profile</button>
          <button className="action-button" onClick={handleCompletedButtonClick} disabled={completedButtonClicked}>Completed</button>
        </div>
      </div>
    </Layout>
  );
};

export default AppointmentDetails;
